export default [{
  title: 'Add Your NFTs',
  content: [
    'Connect the wallet and select the NFTs you',
    'would like to re-create.',
  ]
}, {
  title: 'Create Your Artwork',
  content: [
    'Add components to your NFT ',
    'and show off your unique creativity'
  ]
}, {
  title: 'Share Yours Artwork',
  content: [
    'Keep your social media feed fresh and ',
    'entertaining.',
  ]
}]
