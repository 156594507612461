export default [{
  time: 'Sep 2022',
  cards: [{
    title: 'DogeClub OG',
    width: 316,
    smallWidth: 237,
    finished: true,
    content: [
      'DOGC is the OG project. As the “Ecosystem Core”, the purpose of all construction is to send the DOGC to the moon.',
      'Our vision is to create a multi-dimensional brand.'
    ],
    plainText: true
  }]
}, {
  time: 'Nov 2022',
  cards: [{
    title: 'M1 Mutant Doge & Mega Mutant Doge',
    width: 335,
    smallWidth: 251,
    finished: true,
    content: [
      'Mutant Doge Club is an entirely original project.',
      'As part of the DOGC series, holders will unlock more utilities.'
    ]
  }]
}, {
  time: 'Late Dec 2022',
  cards: [{
    title: 'MEME Party voyager beta version',
    width: 400,
    smallWidth: 300,
    content: [
      'We invite you to participate in the voyager beta version of meme Party.',
      'Continue to update new art material and new features.',
      'Continue to expand the list of permitted NFT projects.',
      'Organize a series of meme competitions. Xmas meme competition is coming soon.'
    ]
  }]
}, {
  time: 'Jan 2023',
  cards: [{
    title: 'Mysterious Creature',
    width: 478,
    smallWidth: 459,
    content: [
      'DOGC & MDOG holders will have whitelist spots.',
      'Holders will have access to exclusive material in MEME Party.',
      'Activate the fund pool for building the DOGC ecosystem',
      'The ownership of NFT will be viewed as stock rights of the DOGC ecosystem, share the profit of creator fee, meme party and more in the future.'
    ]
  }]
}, {
  time: '2023',
  cards: [{
    title: 'MEME Party Trailblazer Beta version',
    width: 551,
    smallWidth: 500,
    content: [
      'Open the token payment channel and try to interact more with $DOGE and $APE.',
      'Open exclusive art channel (DOGE, PEPE, BAYC, etc.)',
      'Open artist‘s personal page and store, everyone can be the artist.',
      'Organize all kinds of activities&events, let more people participate, occupy the market on social media.',
      'Develop MEME Party APP (Androd&ios)',
      'Share the profit with “mysterious creature” holders.'
    ]
  }, {
    title: 'Exploration of the Global Market of MEME IP',
    width: 484,
    smallWidth: 363,
    content: [
      'Launch Incubate hub of meme IP, expand the influence of IP.',
      'Share the profit with “mysterious creature” holders.'
    ]
  }, {
    title: 'Doge kennel',
    width: 484,
    smallWidth: 363,
    content: [
      'A unique online platform for doge lovers.',
      'The platform offers users a fun and engaging space to connect with like-minded individuals and showcase their NFT collections.',
      'Share the profit with “mysterious creature” holders.',
    ]
  }, {
    title: 'Season II',
    width: 280,
    content: []
  }]
}, {
  time: 'Future',
  cards: [{
    title: 'DogeClub merch \n(In process)',
    width: 484,
    smallWidth: 363,
    content: [
      'Doge Club merch store is being prepared.',
      'Voxel Doge coming soon. '
    ]
  }, {
    title: 'The exploration of doge trading card game (TCG)',
    width: 551,
    smallWidth: 460,
    content: [
      'Increase the influence of doge in MEME and bring exquisite art.',
      'Gacha cards through tokens such as $DOGE $APE',
      'Holders of DOGC series can receive regular airdrops of card packs',
      'Open the Card Exchange platform for doge enthusiasts to collect and exchange cards.',
      'Share the profit with "mysterious creature" holders.'
    ]
  }, {
    title: 'Exploration of the video game',
    width: 484,
    smallWidth: 363,
    content: [
      'Explore mobile game and pc game. ',
      'Explore gamefi.',
      'Share the profit with "mysterious creature" holders.',
    ]
  }, {
    title: 'Token',
    width: 484,
    smallWidth: 363,
    content: [
      'DOGC',
      'MEME Party: Explore the market and the possibility of token.',
      'Seek various cooperation and investment',
      'Share the profit with “mysterious creature” holders.'
    ]
  }]
}]
