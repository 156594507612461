<template>
  <div class="icon-svg">
    <svg width="30" height="30" viewBox="0 0 30 30" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
      <path v-if="name==='frame'" id="Union" fill-rule="evenodd" clip-rule="evenodd" d="M3.75 10.2826C1.58138 9.63718 0 7.62827 0 5.25C0 2.35051 2.35051 0 5.25 0C7.62827 0 9.63718 1.58138 10.2826 3.75H19.7174C20.3628 1.58138 22.3717 0 24.75 0C27.6495 0 30 2.35051 30 5.25C30 7.62827 28.4186 9.63718 26.25 10.2826V19.7174C28.4186 20.3628 30 22.3717 30 24.75C30 27.6495 27.6495 30 24.75 30C22.3717 30 20.3628 28.4186 19.7174 26.25H10.2826C9.63718 28.4186 7.62827 30 5.25 30C2.35051 30 0 27.6495 0 24.75C0 22.3717 1.58138 20.3628 3.75 19.7174L3.75 10.2826ZM3 5.25C3 4.00736 4.00736 3 5.25 3C6.49264 3 7.5 4.00736 7.5 5.25C7.5 6.49264 6.49264 7.5 5.25 7.5C4.00736 7.5 3 6.49264 3 5.25ZM6.75 19.7174L6.75 10.2826C8.44333 9.77863 9.77863 8.44333 10.2826 6.75H19.7174C20.2214 8.44333 21.5567 9.77863 23.25 10.2826V19.7174C21.5567 20.2214 20.2214 21.5567 19.7174 23.25H10.2826C9.77863 21.5567 8.44333 20.2214 6.75 19.7174ZM22.5 24.75C22.5 23.5074 23.5074 22.5 24.75 22.5C25.9926 22.5 27 23.5074 27 24.75C27 25.9926 25.9926 27 24.75 27C23.5074 27 22.5 25.9926 22.5 24.75ZM27 5.25C27 6.49264 25.9926 7.5 24.75 7.5C23.5074 7.5 22.5 6.49264 22.5 5.25C22.5 4.00736 23.5074 3 24.75 3C25.9926 3 27 4.00736 27 5.25ZM3 24.75C3 23.5074 4.00736 22.5 5.25 22.5C6.49264 22.5 7.5 23.5074 7.5 24.75C7.5 25.9926 6.49264 27 5.25 27C4.00736 27 3 25.9926 3 24.75Z" fill="currentColor"/>

      <g v-else-if="name==='nfts'" id="Group 24">
        <path id="Vector" d="M15.0003 23.3337C14.0798 23.3337 13.3337 22.5875 13.3337 21.667V16.667H8.33366C7.41319 16.667 6.66699 15.9208 6.66699 15.0003C6.66699 14.0798 7.41319 13.3337 8.33366 13.3337H13.3337V8.33366C13.3337 7.41318 14.0798 6.66699 15.0003 6.66699C15.9208 6.66699 16.667 7.41318 16.667 8.33366V13.3337H21.667C22.5875 13.3337 23.3337 14.0798 23.3337 15.0003C23.3337 15.9208 22.5875 16.667 21.667 16.667H16.667V21.667C16.667 22.5875 15.9208 23.3337 15.0003 23.3337Z" fill="currentColor"/>
        <path id="Vector_2" fill-rule="evenodd" clip-rule="evenodd" d="M0 8.33333C0 3.73097 3.73097 0 8.33333 0H21.6667C26.269 0 30 3.73097 30 8.33333V21.6667C30 26.269 26.269 30 21.6667 30H8.33333C3.73097 30 0 26.269 0 21.6667V8.33333ZM8.33333 3.33333C5.57192 3.33333 3.33333 5.57192 3.33333 8.33333V21.6667C3.33333 24.4282 5.57192 26.6667 8.33333 26.6667H21.6667C24.4282 26.6667 26.6667 24.4282 26.6667 21.6667V8.33333C26.6667 5.57192 24.4282 3.33333 21.6667 3.33333H8.33333Z" fill="currentColor"/>
      </g>

      <g v-else-if="name==='components'" id="Group 1461">
        <path id="Vector" d="M14.354 14.6724C13.7804 15.246 12.8504 15.246 12.2769 14.6724C11.7033 14.0989 11.7033 13.1688 12.2769 12.5953C12.8504 12.0216 13.7804 12.0216 14.354 12.5953C14.9275 13.1688 14.9275 14.0989 14.354 14.6724Z" fill="currentColor"/>
        <path id="Vector_2" d="M15.3921 17.4702C15.9656 18.0437 16.8957 18.0437 17.4692 17.4702C18.0428 16.8966 18.0428 15.9666 17.4692 15.3931C16.8957 14.8195 15.9656 14.8195 15.3921 15.3931C14.8185 15.9666 14.8185 16.8966 15.3921 17.4702Z" fill="currentColor"/>
        <path id="Vector_3" fill-rule="evenodd" clip-rule="evenodd" d="M26.5575 2.15096C23.6896 -0.716988 19.0398 -0.716988 16.1718 2.15096L12.8161 5.50658L12.7965 5.52589L5.52643 12.7959L5.50712 12.8156L2.15096 16.1718C-0.716988 19.0397 -0.716988 23.6895 2.15096 26.5576L3.18955 27.5962C6.0575 30.4641 10.7074 30.4641 13.5753 27.5962L27.5961 13.5753C30.464 10.7074 30.464 6.0575 27.5961 3.18955L26.5575 2.15096ZM6.56519 15.9118L4.22813 18.2489C2.50735 19.9697 2.50735 22.7596 4.22813 24.4805L5.2667 25.519C6.98748 27.2397 9.7774 27.2397 11.4982 25.519L13.8352 23.1819L6.56519 15.9118ZM23.1824 13.8347L25.5189 11.4982C27.2398 9.77739 27.2398 6.98746 25.5189 5.2667L24.4804 4.22811C22.7596 2.50735 19.9696 2.50735 18.249 4.22811L15.9124 6.56465L23.1824 13.8347ZM8.64234 13.8347L13.8352 8.64181L21.1051 15.9116L15.9122 21.1046L8.64234 13.8347Z" fill="currentColor"/>
      </g>

      <g v-else-if="name==='template'" id="Group 1626">
        <rect id="Rectangle 1332" x="1.5" y="1.5" width="27" height="27" rx="6.5" stroke="#393E48" stroke-width="3"/>
        <path id="Vector 171" d="M15 2V29" stroke="#393E48" stroke-width="3"/>
        <path id="Vector 172" d="M29 15L16 15" stroke="#393E48" stroke-width="3"/>
      </g>

      <g v-else-if="name==='background'" id="Group 1577">
        <g id="Group 1466">
          <path id="Vector" fill-rule="evenodd" clip-rule="evenodd" d="M0 8.33333C0 3.73097 3.73097 0 8.33333 0H21.6667C26.269 0 30 3.73097 30 8.33333V21.6667C30 26.269 26.269 30 21.6667 30H8.33333C3.73097 30 0 26.269 0 21.6667V8.33333ZM8.33333 3.33333C5.57192 3.33333 3.33333 5.57192 3.33333 8.33333V21.6667C3.33333 24.4282 5.57192 26.6667 8.33333 26.6667H21.6667C24.4282 26.6667 26.6667 24.4282 26.6667 21.6667V8.33333C26.6667 5.57192 24.4282 3.33333 21.6667 3.33333H8.33333Z" fill="currentColor"/>
        </g>
        <rect id="Rectangle 1024" x="9.5" y="9.5" width="11" height="11" rx="2.5" stroke="#393E48" stroke-width="3"/>
      </g>

    </svg>
  </div>
</template>

<script>
export default {
  name: 'svgIcon',
  props:{
    name: {
      type: String,
      default: 'default'
    }
  },
  data() {
    return {};
  },
  methods:{}
};
</script>

<style scoped lang="less">
.icon-svg {
  width: 2.14rem;
  height: 2.14rem;

  svg {
    width: 100%;
    height: auto;
  }
}

</style>
