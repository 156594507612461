<template>
  <div class="header-wrapper">
    <div class="connect-btn" @click="showConnect">{{$store.state.address || 'Connect'}}</div>
    <div class="pop-wrapper" v-if="isConnected">
      <div class="pop-btn disconnect" @click="disconnect">Disconnect</div>
    </div>

    <div class="connect-mask" @click="showMask = false" v-if="showMask">
      <div class="connect-wrapper" @click.stop>
        <h1 class="wrapper-title">Connect Wallet</h1>
        <div class="btn metamask" @click="connect('metamask')">
          <p class="title">MetaMask</p>
          <p class="content">Connect to your MetaMask Wallet</p>
          <img class="icon" src="../assets/connect/metamask.png" alt="">
        </div>
        <div class="btn wallet-connect" @click="connect('scan')">
          <p class="title">WalletConnect</p>
          <p class="content">Scan with WalletConnect wo connect</p>
          <img class="icon" src="../assets/connect/connectwallet.png" alt="">
        </div>
        <div class="btn coinbase" @click="connect('coinbase')">
          <p class="title">Coinbase</p>
          <img class="icon" src="../assets/connect/coinbase.png" alt="">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import store from "@/store";
import Ether from "@/utils/wallet";
export default {
  name: "ConnectWallet",
  computed: {
    isConnected() {
      return store.state.address !== ''
    },
  },
  data() {
    return {
      wallet: null,
      showMask: false
    }
  },
  mounted() {
    this.wallet = new Ether.Wallet()
    this.wallet.init()
  },
  methods: {
    async connect(type) {
      if (type === 'metamask') {
        await this.wallet.getConnect('metamask')
        this.showMask = false
      } else if (type === 'coinbase') {
        await this.wallet.getConnect('coinbase')
        this.showMask = false
      } else {
        await this.wallet.connectByScan()
        this.showMask = false
      }
    },
    disconnect() {
      store.commit('SetAddress', '')
      store.commit('SetSign', '')
      store.commit('SetToken', '')
      localStorage.setItem('address', '')
      localStorage.setItem('token', '')
      localStorage.setItem('sign', '')
      location.reload()
    },
    showConnect() {
      if (this.showMask || store.state.address !== '') return
      this.showMask = true
    }
  }
}
</script>

<style scoped lang="less">
.header-wrapper {
  height: 60px;
  display: flex;
  align-items: center;
  position: relative;
  .connect-btn {
    width: 125px;
    height: 40px;
    background: #60C96F;
    border-radius: 23px;
    text-align: center;
    font-size: 14px;
    font-family: Montserrat-Bold, Montserrat;
    font-weight: bold;
    color: #FFFFFF;
    line-height: 40px;
    padding: 0 23px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer;

  }

  &:hover {
    .pop-wrapper {
      display: block;
    }
  }

  .pop-wrapper {
    display: none;
    width: 125px;
    position: absolute;
    top: 60px;
    left: 0;
    padding: 11px;
    background-color: white;
    border-radius: 8px;
    box-shadow: 4px 4px 8px 0 rgba(233,233,233,0.25);
    .pop-btn {
      background-color: #EFEFEF;
      color: black;
      cursor: pointer;
      font-family: Montserrat-Medium, Montserrat;
      font-weight: 500;
      font-size: 14px;
      height: 32px;
      line-height: 32px;
      text-align: center;
      border-radius: 10px;
    }
  }

  .connect-mask {
    position: fixed;
    z-index: 999;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: rgba(0,0,0,0.7);
    display: flex;
    align-items: center;
    justify-content: center;

    .connect-wrapper {
      width: 68.6rem;
      height: 56.4rem;
      border-radius: 2.5rem;
      background: white;
      padding: 2.4rem 2rem 2rem 2rem;

      .wrapper-title {
        width: 24.3rem;
        height: 3.7rem;
        font-size: 3rem;
        font-family: Montserrat-Bold, Montserrat;
        font-weight: bold;
        color: #212121;
        line-height: 3.5rem;
        width: 100%;
        text-align: center;
        margin-bottom: 2.3rem;
      }

      .btn {
        width: 64.6rem;
        height: 14rem;
        border-radius: 1.4rem;
        transition-duration: 0.3s;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        padding-left: 5.5rem;
        position: relative;
        cursor: pointer;

        &:hover {
          transform: scale(1.02);
        }

        .title {
          font-size: 2.5rem;
          font-family: Montserrat-Bold, Montserrat;
          font-weight: bold;
          color: #FFFFFF;
          line-height: 3rem;
          margin-bottom: 0.4rem;
        }

        .content {
          font-size: 1.4rem;
          font-family: Montserrat-Regular, Montserrat;
          font-weight: 400;
          color: rgba(255,255,255,0.79);
          line-height: 1.7rem;
        }

        .icon {
          position: absolute;
        }

        &.metamask {
          background: linear-gradient(90deg, #EC672B 0%, #FADC52 100%);
          margin-bottom: 2rem;
          .icon {
            width: 8.8rem;
            top: 2.6rem;
            right: 4.6rem;
          }
        }

        &.wallet-connect {
          background: linear-gradient(90deg, #8218F5 0%, #0700F5 100%);
          margin-bottom: 2rem;
          .icon {
            width: 7rem;
            top: 5rem;
            right: 5.5rem;
          }
        }

        &.coinbase {
          background: #3677FF;
          .icon {
            width: 8rem;
            height: 8rem;
            top: 3.4rem;
            right: 5.4rem;
          }
        }
      }
    }
  }
}

</style>
