<template>
  <div class="tool-bar" @click.stop>
    <div
      v-tooltip.top="tips[0]"
      class="btn select" @click="select"></div>
    <div
      v-tooltip.top="tips[1]"
      :class="{inactive: mSelectMode !== 'one'}" class="btn copy" @click="copy"></div>
    <div
      v-tooltip.top="tips[2]"
      :class="{inactive: mSelectMode !== 'one'}" class="btn flip-x" @click="flip('X')"></div>
    <div
      v-tooltip.top="tips[3]"
      :class="{inactive: mSelectMode !== 'one'}" class="btn flip-y" @click="flip('Y')"></div>
  </div>
</template>

<script>
import select from "@/mixins/select";
import {v4 as uuid} from 'uuid';
import {getImgStr} from "@/utils/utils";

const defaultPosition = {
  left: 100, top: 100, shadow: '', fontFamily: 'arial'
}

export default {
  name: "ToolBar",
  mixins: [select],
  data() {
    return {
      uploadCount: 0,
      tips: ['move', 'duplicate', 'flip', 'flip']
    };
  },
  methods: {
    select() {
      this.$emit('moveMode')
      const id = localStorage.getItem('activeId')
      this.canvas.c.isDrawingMode = false;
      this.canvas.c.fire('mode:normal')
      this.canvas.c.requestRenderAll()
      this.canvas.c.discardActiveObject()
      this.canvas.c.forEachObject((o) => {
        if (o.id && o.id === id) {
          o.set('erasable', false);
          this.canvas.c.setActiveObject(o)
          this.canvas.c.renderAll()
        }
      })

    },
    copy() {
      if (this.mSelectMode !== 'one') return
      const activeObject = this.canvas.c.getActiveObject();
      activeObject.clone(cloned => {
        this.canvas.c.discardActiveObject()
        const grid = 10
        cloned.set({
          left: cloned.left + grid,
          top: cloned.top + grid,
          id: uuid(),
        })
        cloned.set('erasable', false);
        this.canvas.c.add(cloned)
        this.canvas.c.setActiveObject(cloned);
        this.canvas.c.requestRenderAll();
      })
    },
    flip(type) {
      if (this.mSelectMode !== 'one') return
      let scale
      const activeObject = this.canvas.c.getActiveObject();
      if (activeObject.type === 'i-text' || activeObject.type === 'text' || activeObject.type === 'textbox') {
        scale = 1
      } else {
        const originSize = activeObject.getOriginalSize()
        if (type === 'X') {
          scale = activeObject.getScaledWidth() / originSize.width
        } else {
          scale = activeObject.getScaledHeight() / originSize.height
        }
      }
      scale = -1 * scale
      activeObject.set('scale' + type, scale).setCoords()
      this.canvas.c.requestRenderAll()
    }
  }
}
</script>

<style scoped lang="less">
.tool-bar {
  display: flex;
  height: 70px;
  background: #FFFFFF;
  width: 235px;
  border-radius: 18px;
  padding: 15px;
  display: flex;
  justify-content: space-between;

  .btn {
    width: 40px;
    height: 40px;
    cursor: pointer;
  }
}

.btn {
  background-size: contain;

  &.select {
    background-image: url("../assets/tools/select.png");
    &:hover {
      background-image: url("../assets/tools/selecta.png");
    }
  }

  &.copy {
    background-image: url("../assets/tools/copy.png");

    &:hover {
      background-image: url("../assets/tools/copya.png");
    }
  }

  &.flip-x {
    background-image: url("../assets/tools/flip-x.png");

    &:hover {
      background-image: url("../assets/tools/flip-xa.png");
    }
  }

  &.flip-y {
    background-image: url("../assets/tools/flip-y.png");

    &:hover {
      background-image: url("../assets/tools/flip-ya.png");
    }
  }

  &.arrange {
    background-image: url("../assets/tools/arrange.png");

    &:hover {
      background-image: url("../assets/tools/arrangea.png");
    }
  }

  &.inactive {
    opacity: 0.6;
    &.copy {
      &:hover {
        background-image: url("../assets/tools/copy.png");
      }
    }

    &.flip-x {
      &:hover {
        background-image: url("../assets/tools/flip-x.png");
      }
    }

    &.flip-y {
      &:hover {
        background-image: url("../assets/tools/flip-y.png");
      }
    }
  }
}

</style>
