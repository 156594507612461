<template>
  <div class="nft-wrapper">
    <div class="wrapper-header" :class="{active: !fold}"
         @click="flipFold">
      <img class="profile" :src="contract.profile" alt="">
      <p class="header-title">{{ contract.name }}</p>
      <p class="header-holder">hold x{{ contract.totalBalance }}</p>
      <img class="flip-btn" src="../assets/arrow-up.png">
    </div>
    <div class="wrapper-content">
      <div class="image-wrapper" v-for="(item, index) in nfts" :key="item" >
        <img @click="addItem(index, 'origin')" :src="item" alt="">
        <div class="btn no-bg" v-if="hasNoBg" @click="addItem(index, 'nobg')"></div>
        <div class="btn sticky" v-if="hasTricky" @click="addItem(index, 'sticky')"></div>
      </div>
    </div>
  </div>
</template>

<script>
import nftRequest from "@/utils/nftRequest";
import select from '@/mixins/select'
import {v4 as uuid} from "uuid";

export default {
  name: "NftCard",
  props: ['contract'],
  data() {
    return {
      fold: true,
      nfts: [],
      nftIds: [],
      total: 0,
      count: 0,
      noBgBase: {
        '0x9f648eef45b73e1A32ce7b624A44266226a85d88': 'https://dogc.s3.amazonaws.com/dogenobg/', // dogc
        '0xdC09248d3dEC997911EbCC2C6730764DD63E39B8': 'https://dogc.s3.amazonaws.com/mutantnobg/'
      },
      stickyBase: {
        '0x9f648eef45b73e1A32ce7b624A44266226a85d88': 'https://dogc.s3.amazonaws.com/dogcsticker/'
      }
    }
  },
  mixins: [select],
  computed: {
    hasNoBg() {
      const noBgContracts = ['0x9f648eef45b73e1A32ce7b624A44266226a85d88', '0xdC09248d3dEC997911EbCC2C6730764DD63E39B8']
      return noBgContracts.indexOf(this.contract.address) > -1
    },
    hasTricky() {
      // return false
      return this.contract.address === '0x9f648eef45b73e1A32ce7b624A44266226a85d88'
    }
  },
  methods: {
    async flipFold() {
      console.log(this.contract)
      this.fold = !this.fold
      if (this.nfts.length <= 0) {
        const res = await nftRequest.get('/getNFTs', {
          owner: this.$store.state.address,
          contractAddresses: [this.contract.address]
        })
        const data = res.data
        this.count = data.totalCount
        this.nfts = data.ownedNfts.map(item => {
          this.nftIds.push(Number(item.id.tokenId))
          return this.contract.baseurl ? this.contract.baseurl + Number(item.id.tokenId) + '.png' : item.media[0].gateway
        })
      }
    },
    addItem(index, type) {
      this.canvas.c.fire('loading:show')
      let url = ''
      if (type === 'origin')  {
        url = this.nfts[index]
      } else if (type === 'nobg') {
        url = this.noBgBase[this.contract.address] + this.nftIds[index] + '.png'
      } else {
        url = this.stickyBase[this.contract.address] + this.nftIds[index] + '.png'
      }
      const cWidth = this.canvas.c.getWidth()
      const cHeight = this.canvas.c.getHeight()
      const nftWidth = Math.min(cWidth, cHeight)

      let img = document.createElement('img');
      img.setAttribute('crossorigin', 'anonymous');
      img.src = url + '?not-from-cache-please';

      img.onload = () => {
        const imgInstance = new this.fabric.Image(img, {
          id: uuid(),
          name: this.contract.name + this.count,
          left: 0, top: 0,
          src: img.src,
          erasable: false,
          isNft: true
        });
        this.count += 1;
        // 设置缩放
        imgInstance.scale(0.5);
        this.canvas.c.add(imgInstance)
        this.canvas.c.setActiveObject(imgInstance);
        this.canvas.c.renderAll()
        this.canvas.c.fire('loading:end')
      }
    }, //4184
  }
}
</script>

<style scoped lang="less">
.nft-wrapper {
  width: 225px;
  background: #F8F8F8;
  border-radius: 16px;
  padding: 17px 13px;
  position: relative;
  margin-bottom: 13px;

  .wrapper-header {
    padding-left: 43px;
    cursor: pointer;

    .profile {
      width: 36px;
      height: 36px;
      position: absolute;
      top: 17px;
      left: 13px;
      border-radius: 4px;
    }

    .header-title {
      width: 138px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-size: 14px;
      font-family: Montserrat-SemiBold, Montserrat;
      font-weight: 600;
      color: #000000;
      line-height: 18px;
      margin-bottom: 2px;
    }

    .header-holder {
      font-size: 12px;
      font-family: Montserrat-Medium, Montserrat;
      font-weight: 500;
      color: #8A8A8A;
      line-height: 14px;
    }

    .flip-btn {
      position: absolute;
      right: 9px;
      top: 19px;
      width: 14px;
      transform: rotate(180deg);
      transition-duration: 0.2s;
    }

  }

  .wrapper-content {
    display: flex;
    flex-wrap: wrap;
    margin-top: 9px;
    .image-wrapper {
      position: relative;
      margin-right: 6px;
      margin-bottom: 6px;
      border-radius: 4px;
      width: 60px;
      height: 60px;
      overflow: hidden;
    }
    img {
      width: 60px;
      height: 60px;
    }
    .btn {
      position: absolute;
      right: 3px;
      background-size: contain;
      background-repeat: no-repeat;
      width: 22px;
      height: 22px;
      cursor: pointer;

      &.no-bg {
        background-image: url("../assets/add-nobg.png");
        top: 3px;
      }
      &.sticky {
        bottom: 3px;
        background-image: url("../assets/add-sticky.png");
      }
    }
  }
}


</style>
