<template>
  <div class="home">
    <!--    top btns-->
    <div class="top-btns">
      <div class="center-btns">
        <set-history v-if="show" />
        <zoom-frame @fix="setFixedPosition" v-if="show" />
      </div>
      <top-btns />
    </div>
    <!--    left sidebar-->
    <div
      v-if="show"
      class="sidebar-wrapper">
      <div class="menu-wrapper">
        <div class="menu move" :class="{active: frameMoving}" @click="moveFrameMode"></div>
        <div
          v-for="(item, index) in menus"
          :key="item"
          v-tooltip.right="tips[index]"
          :class="[item, menuActive === index ? 'active' : '']"
          @click="selectMenu(index)"
          class="menu">
        </div>
      </div>
      <div class="content" :class="[hideLeft ? 'hide' : '']">
        <h1>{{menuTitles[menuActive]}}</h1>
        <div @click="hideLeft = true" class="close-btn"></div>
        <!-- frame -->
        <div v-show="menuActive === 0" class="left-panel">
          <p class="description">You can customize the canvas size.</p>
          <set-size @moveFrame="moveFrame"></set-size>
        </div>
        <!-- template -->
        <div v-show="menuActive === 1" class="left-panel">
          <p class="description">You can choose more than one NFT.</p>
          <add-nft />
        </div>
        <div v-show="menuActive === 2" class="left-panel">
          <add-text @showAttr="showAttribute"/>
        </div>
        <!-- components -->
        <div v-show="menuActive === 3" class="left-panel">
          <svgEl></svgEl>
        </div>
      </div>
    </div>
    <!-- frame board -->
    <div @click="cancelActive" class="frame-board" ref="board">
      <div
        @click.stop
        :style="canvasBoxStyle"
        class="canvas-box"
        ref="canvas">
        <canvas id="canvas"></canvas>
      </div>
      <ToolBar @moveMode="removeMoveMod" class="tool-bar" />
    </div>
    <!-- attributes -->
    <div class="right-bar" :class='{show: !hideAttr}'>
      <SetAttributes v-if="show" @close="closeAttribute" @open="showAttribute"  />
      <set-layer v-if="show" class="set-layer" />
    </div>
    <div
      class="loading-mask"
      @click="loading = false"
      v-if="loading">
      <img src="../assets/loading.gif" alt="">
    </div>
  </div>
</template>

<script>
// header
import AppHeader from '@/components/AppHeader'
import ZoomFrame from "@/components/ZoomFrame";
import SetHistory from "@/components/SetHistory";
import TopBtns from "@/components/TopBtns";
// left sidebar
import svgEl from '@/components/svgEl.vue'
import setSize from '@/components/setSize.vue'
import AddNft from "@/components/AddNft";
import AddText from '@/components/AddText'

// functions
import EventHandle from '@/utils/eventHandler'
import custromHotkeys from '@/plugin/hotkeys'

// board
import ToolBar from "@/components/ToolBar";

// right sidebar
import SetAttributes from "@/components/SetAttributes";
import SetLayer from "@/components/SetLayer";

import {fabric} from 'fabric';
import 'fabric-history'
import hotkeys from 'hotkeys-js';
import {v4 as uuid} from "uuid";

const zoomRate = [400, 200, 150, 100, 75, 50, 25, 10]
const event = new EventHandle()
const canvas = {}
export default {
  name: 'HomeView',
  provide: {
    canvas,
    fabric,
    event
  },
  computed: {
    // canvas position
    canvasBoxStyle () {
      return {
        transform: `translate(${this.targetX}px, ${this.targetY}px)`
      }
    },
  },
  data() {
    return {
      loading: false,
      menuActive: 0,
      show: false,
      select: null,
      isLock: false,
      menus: ['frame', 'nft', 'text', 'component', 'emoji', 'template', 'background', 'my'],
      tips: ['Canvas size', 'Add NFTs', 'Text', 'Component', 'Emoji', 'Format',  'Background', 'My Components'],
      menuTitles: ['Frame', 'Add NFTs', 'Text', 'Components'],
      targetX: 0,
      targetY: 0,
      hideLeft: false,
      hideAttr: true,
      frameMoving: false,

      // move frame
      isDrag: false,
      x: 0,
      y: 0,
      originX: 0,
      originY: 0
    };
  },
  components: {
     setSize, svgEl, ToolBar, SetAttributes, AddNft, AddText, SetLayer, ZoomFrame, SetHistory, TopBtns
  },
  created() {
    this.$Spin.show();
  },
  mounted() {
    this.canvas = canvas.c = new fabric.Canvas('canvas', {
      controlsAboveOverlay: true,
      preserveObjectStacking: false
    });
    canvas.zoom = 1
    this.canvas.set('backgroundColor', '#fff')
    this.$Spin.hide();
    event.init(canvas.c)

    canvas.c.on({
      'loading:show': () => {this.loading = true},
      'loading:end': () => {this.loading = false},
    })

    custromHotkeys(canvas.c)
    this.setControlsStyle(fabric)
    this.initCanvasPosition()
    this.addCanvasDrag()
    this.show = true
  },
  methods: {
    moveFrame(value) {
      console.log(value)
      this.targetX += value.x
      this.targetY += value.y
    },
    mouseDownHandler(e) {
      this.isDrag = true
      this.x = e.pageX
      this.y = e.pageY
      this.originX = this.targetX
      this.originY = this.targetY
      document.addEventListener('mousemove', this.mouseMoveHandler)
      document.onmouseup = () => {
        this.isDrag = false
        document.removeEventListener('mousemove', this.mouseMoveHandler)
      }
    },
    mouseMoveHandler(e) {
      if (this.isDrag) {
        this.targetX = (e.pageX - this.x) + this.originX
        this.targetY = (e.pageY - this.y) + this.originY
      }
    },
    removeMoveMod() {
      const canvasWrapper = this.$refs.canvas
      if (this.frameMoving) {
        this.frameMoving = false
        canvasWrapper.removeEventListener('mousedown', this.mouseDownHandler)
      }
    },
    moveFrameMode() {
      const canvasWrapper = this.$refs.canvas
      if (this.frameMoving) {
        this.frameMoving = false
        canvasWrapper.removeEventListener('mousedown', this.mouseDownHandler)
      } else {
        this.frameMoving = true
        canvasWrapper.addEventListener('mousedown', this.mouseDownHandler)
      }
    },
    selectMenu(index) {
      if (index > 3) return
      this.menuActive = index;
      this.hideLeft = false;
    },
    cancelActive() {
      canvas.c.discardActiveObject().renderAll()
    },
    showAttribute() {
      this.hideAttr = false
    },
    closeAttribute() {
      this.hideAttr = true
    },
    initCanvasPosition() {
      this.targetX = -225
      this.targetY = -225
    },
    setFixedPosition() {
      this.targetX = parseInt(-1 * canvas.c.getWidth() / 2)
      this.targetY = parseInt(-1 * canvas.c.getHeight() / 2) - 50
    },
    addCanvasDrag() {
      const canvasWrapper = this.$refs.canvas
      let isDrag = false
      let x = 0 // the distance between slide and click point
      let y = 0
      let originX = this.targetX
      let originY = this.targetY
      const mouseMoveHandler = (e) => {
        if (isDrag) {
          this.targetX = (e.pageX - x) + originX
          this.targetY = (e.pageY - y) + originY
        }
      }
      const mouseDonwHandler = (e) => {
        isDrag = true
        x = e.pageX
        y = e.pageY
        originX = this.targetX
        originY = this.targetY
        document.addEventListener('mousemove', mouseMoveHandler)
        document.onmouseup = () => {
          isDrag = false
          document.removeEventListener('mousemove', mouseMoveHandler)
        }
      }

      hotkeys('space', {keyup: true}, function(event, handler) {
        if (event.type === 'keydown') {
          canvasWrapper.addEventListener('mousedown', mouseDonwHandler)
        } else {
          canvasWrapper.removeEventListener('mousedown', mouseDonwHandler)
        }
      })

    },
    setControlsStyle(fabric) {
      fabric.Object.prototype.transparentCorners = false;
      fabric.Object.prototype.cornerSize = 10;
      fabric.Object.prototype.cornerStrokeColor = '#C2C2C2';
      fabric.Object.prototype.cornerColor = '#ffffff';
      fabric.Object.prototype.cornerStyle = 'circle';
      fabric.Object.prototype.borderColor = '#85CCF9';
    }
  }
};
</script>
<style lang="less" scoped>
.home {
  height: calc(100vh - 60px);
  display: flex;
  position: relative;
  width: 100%;
  overflow: hidden;
}
.top-btns {
  height: 60px;
  position: fixed;
  z-index: 10;
  top: 0;
  width: calc(100% - 410px);
  left: 50%;
  margin: 0 auto;
  margin-left: calc(205px - 50%);
  display: flex;
  justify-content: center;
  .center-btns {
    display: flex;
  }
  .right-btns {
    position: absolute;
  }
}

.sidebar-wrapper {
  z-index: 1;
  width: auto;
  height: calc(100vh - 66px);
  background: #fff;
  display: flex;
  position: relative;
  overflow: visible;

  .menu-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 36px 15px;
    width: 70px;
    background-color: white;
    z-index: 1;
    .menu {
      width: 40px;
      height: 40px;
      margin-bottom: 20px;
      background-size: contain;
      cursor: pointer;
      color: #393E48;
      background-color: #FBFBFB;
      border-radius: 10px;
      display: flex;
      position: relative;
      justify-content: center;
      align-items: center;

      &.emoji, &.background, &.template, &.my {
        opacity: 0.3;
        cursor: auto;
      }

      @graymenus:  template, background, emoji, my;
      .for(@data2, @i: 1) when(@i =< length(@data2)) {
        @item: extract(@data2, @i);
        &.@{item} {
          background-image: url('../assets/sidebar/@{item}.png');
        }
        .for(@data2, (@i + 1));
      }
      .for(@graymenus);

      &.move.active {
        background-image: url('../assets/sidebar/movea.png');
      }

      @menus: move, frame, nft, text, component;

      @colors: red, green, blue, black;
      .for(@data, @i: 1) when(@i =< length(@data)) {
        @item: extract(@data, @i);
        &.@{item} {
          background-image: url('../assets/sidebar/@{item}.png');

          &.active, &:hover {
            background-image: url('../assets/sidebar/@{item}a.png');
          }
        }
        .for(@data, (@i + 1));
      }
      .for(@menus);
    }
  }

  .content {
    position: absolute;
    width: 286px;
    padding-top: 26px;
    padding-left: 17px;
    height: calc(100vh - 66px);
    transition-duration: 0.3s;
    right: -286px;
    background-color: white;

    &.hide {
      right: 0;
    }
    .close-btn {
      position: absolute;
      right: 18px;
      top: 25px;
      width: 32px;
      height: 32px;
      background-image: url("../assets/close-left.png");
      background-size: contain;
      cursor: pointer;
    }
    h1 {
      font-size: 24px;
      font-family: Montserrat-Bold, Montserrat;
      font-weight: bold;
      color: #000000;
      line-height: 28px;
    }
    .description {
      font-size: 12px;
      font-family: Montserrat-Medium, Montserrat;
      font-weight: 500;
      color: #8A8A8A;
      line-height: 16px;
      margin-top: 10px;
      margin-bottom: 30px;
    }
    .search-nft {
      height: 50px;
      margin-bottom: 25px;
      visibility: hidden;
    }
  }
}

.frame-board {
  position: relative;
  width: calc(100% - 70px);
  background-color: #F8F8F8;
  overflow: hidden;

  .tool-bar {
    position: absolute;
    left: 50%;
    margin-left: -145px;
    bottom: 30px;
  }
}
.right-bar {
  width: 286px;
  position: absolute;
  background-color: white;
  top: 0;
  height: calc(100vh - 66px);
  padding: 0 24px;
  right: -286px;
  transition-duration: 0.3s;

  &.show {
    right: 0;
  }

  .connect-wrapper {
    position: absolute;
    top: 0;
    left: 0;
  }

  .set-layer {
    left: -200px;
    position: absolute;
    bottom: 30px;
  }
}
.icon {
  display: block;
}

.canvas-box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

#canvas {
  width: 450px;
  height: 450px;
  margin: 0 auto;
}

hr {
  border: 1px solid #eaeaea;
  border-bottom: none;
}
.loading-mask {
  background-color: rgba(0,0,0,0.6);
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 99999;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 100px;
    height: 100px;
  }
}
</style>
