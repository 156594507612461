export default [{
  title: 'Add NFTs',
  content: [
    'Connect the wallet, Select your nfts.',
  ]
}, {
  title: 'Size',
  content: [
    'Applicable to all social media platform, no experience needed,',
    'easy to use'
  ]
}, {
  title: 'Art Materials',
  content: [
    'Massive art collection, adjust the size and position to create',
    'unlimited possibilities'
  ]
}, {
  title: 'Remove background',
  content: [
    'One click to remove the background',
    ''
  ]
}, {
  title: 'Text',
  content: [
    'Simply to adjust the size, font, ',
    'and alignment of your text'
  ]
}, {
  title: 'Design Format',
  content: [
    'A variety of templates to choose from, one-click to change',
    'the material you want'
  ]
}]
