import axios, {AxiosRequestConfig} from 'axios'

// 创建 axios 实例
const request = axios.create({
  // API 请求的默认前缀
  baseURL: 'https://eth-mainnet.g.alchemy.com/nft/v2/M4BtKAhd6cVal5tA26PRDMA0LwovmSJw',
  timeout: 30000, // 请求超时时间
})

export default {
  /**
   *
   * @param {String} url
   * @param {Object} params
   * @param config
   */
  get(url, params, config) {
    return request.get(url, {params, ...config})
  },
}
