<template>
  <section class="custom-scroll">
    <div class="search-wrapper">
      <div class="input-wrapper">
        <div class="select-wrapper">
          <div
            @click.stop="openOptions"
            class="selected-project"><span>{{project.tinyname}}</span></div>
          <div class="options" @click.stop v-show="selecting">
            <div
              class="option"
              @click="selectProject(item)"
              :key="item.tinyname"
              v-for="item in contracts.filter(item => item.baseurl !== null)">
              {{item.tinyname}}
            </div>
          </div>
        </div>
        <div class="input-wrapper">
          <input type="text" v-model="id" @keyup.enter="search">
          <img class="search-icon" src="../assets/icons/search.png" alt="" @click="search">
          <div v-show="id !== ''" class="close-btn" @click="clearSearch"></div>
        </div>
      </div>
      <div  v-if="this.targetNft !== ''" class="result">
        <img :src="this.targetNft" @click="addItem" alt="">
        <p>{{project.tinyname}} #{{id}}</p>
      </div>
    </div>
    <nft-card
      v-for="item in contractsNotEmpty"
      :key="item.name"
      :contract="item"
    ></nft-card>
  </section>
</template>

<script>
import request from "@/utils/request";
import nftRequest from "@/utils/nftRequest";
import NftCard from "@/components/NftCard";
import select from "@/mixins/select";
import {v4 as uuid} from "uuid";

export default {
  name: "AddNft",
  data() {
    return {
      id: '',
      project: 'BAYC',
      contracts: [],
      contractsNotEmpty: [],
      targetNft: '',
      selecting: false
    }
  },
  created() {
    this.getSupportProjects()
  },
  components: {NftCard},
  mixins: [select],
  watch: {
    '$store.state.address': function (val) {
      if (this.contracts.length <= 0 && val !== '') {
        this.getProjects()
      }
    },
    id: function(val) {
      if (val === '') {
        this.targetNft = ''
      }
    }
  },
  methods: {
    addItem() {
      this.canvas.c.fire('loading:show')
      let url = this.targetNft
      const cWidth = this.canvas.c.getWidth()
      const cHeight = this.canvas.c.getHeight()
      const nftWidth = Math.min(cWidth, cHeight)

      let img = document.createElement('img');
      img.setAttribute('crossorigin', 'anonymous');
      img.src = url + '?not-from-cache-please';

      img.onload = () => {
        const imgInstance = new this.fabric.Image(img, {
          id: uuid(),
          name: this.project.tinyname + this.count,
          left: 0, top: 0,
          src: img.src,
          erasable: false,
          isNft: true
        });
        this.count += 1;
        // 设置缩放
        imgInstance.scale(0.5);
        this.canvas.c.add(imgInstance)
        this.canvas.c.setActiveObject(imgInstance);
        this.canvas.c.renderAll()
        this.canvas.c.fire('loading:end')
      }
    }, //4184
    documentClick() {
      this.selecting = false
      document.removeEventListener('click', this.documentClick)
    },
    openOptions() {
      this.selecting = !this.selecting;
      if (this.selecting) {
        document.addEventListener('click', this.documentClick)
      }
    },
    selectProject(item) {
      this.project = item
      this.selecting = false
      document.addEventListener('click', this.documentClick)
    },
    clearSearch() {
      this.id = ''
    },
    search() {
      this.targetNft = this.project.baseurl + this.id + '.png';
    },
    async getSupportProjects() {
      const res = await request.get('/nfts?populate=*')
      this.contracts = res.data.data.map(item => {
        return {
          name: item.attributes.name,
          order: item.attributes.order,
          address: item.attributes.contract_address,
          profile: `https://api.memeparty.app${item.attributes.profile.data.attributes.url}`,
          baseurl: item.attributes.baseurl,
          tinyname: item.attributes.tinyname
        }
      }).sort((a, b) => a.order - b.order)
      console.log(this.contracts)
      this.project = this.contracts[0]
    },
    async getProjects() {
      await this.getContractsForOwner()
    },
    async getContractsForOwner() {
      const res = await nftRequest.get('/getContractsForOwner', {
        owner: this.$store.state.address
      })
      const contracts = res.data.contracts;
      const contractAddresses = res.data.contracts.map(item => item.address);
      const tempNfts = []
      this.contracts.forEach((item) => {
        const contractIndex = contractAddresses.indexOf(item.address.toLowerCase())
        if (contractIndex > -1) {
          tempNfts.push(Object.assign({}, item, {
            totalBalance: contracts[contractIndex].totalBalance
          }))
        }
      })
      this.contractsNotEmpty = tempNfts
    },
  }
}
</script>

<style scoped lang="less">
section {
  overflow: scroll;
  height: calc(100vh - 25rem);
  .search-wrapper {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;

    .input-wrapper {
      width: 252px;
      height: 44px;
      border-radius: 10px 10px 10px 10px;
      opacity: 1;
      border: 1px solid #E7E7E7;
      padding: 4px 4px 4px 10px;
      display: flex;
      align-items: center;

      .select-wrapper {
        width: 63px;
        position: relative;
        height: 44px;
        .selected-project {
          font-size: 14px;
          font-family: Montserrat-SemiBold, Montserrat;
          font-weight: 600;
          color: #000000;
          line-height: 44px;
          position: relative;
          display: flex;
          align-items: center;
          cursor: pointer;
          span {
            display: inline-block;
            width: 46px;
          }

          &:after {
            display: block;
            content: ' ';
            background-image: url("../assets/arrow-up.png");
            width: 11px;
            height: 11px;
            transform: rotate(180deg);
            background-size: contain;
            margin-left: 3px;
          }

          &.selecting {
            &:after {
              transform: rotate(0);
            }
          }
        }
        .options {
          position: absolute;
          top: 47px;
          z-index: 99;
          background: white;
          border: 1px solid #E7E7E7;
          border-radius: 10px;
          width: 74px;
          left: -10px;

          .option {
            text-align: center;
            margin: 2px;
            cursor: pointer;
          }
        }
      }
      .input-wrapper {
        width: 175px;
        height: 36px;
        background: #F7F7F7;
        border-radius: 7px 7px 7px 7px;
        position: relative;
        input {
          text-indent: 30px;
          font-size: 14px;
          font-family: Montserrat-Medium, Montserrat;
          font-weight: 500;
          color: #000000;
          line-height: 36px;
          border: none;
          background: transparent;
          outline: none;
          position: relative;
        }
        img {
          position: absolute;
          width: 20px;
          height: 20px;
          left: 6px;
          top: 8px;
          cursor: pointer;
          opacity: 0.5;
        }
        .close-btn {
          position: absolute;
          right: 0;
          width: 36px;
          height: 36px;
          background-image: url("../assets/close.png");
          background-position: center;
          background-repeat: no-repeat;
          background-size: 14px;
          cursor: pointer;
        }
      }
    }

    .result {
      margin-top: 15px;
      img {
        width: 252px;
        height: 252px;
        border-radius: 10px;
        cursor: pointer;
      }

      p {
        margin-bottom: 3px;
        margin-top: 12px;
        height: 20px;
        font-size: 16px;
        font-family: Montserrat-SemiBold, Montserrat;
        font-weight: 600;
        color: #000000;
        line-height: 19px;
      }
    }
  }

}
</style>
