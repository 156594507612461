const enList = [
  {
    name: 'AlfaSlabOne',
    fontFamily: 'AlfaSlabOne',
  },
  {
    name: 'Anton',
    fontFamily: 'Anton',
  },
  {
    name: 'BerkshireSwash',
    fontFamily: 'BerkshireSwash',
  },
  {
    name: 'BungeeShade',
    fontFamily: 'BungeeShade',
  },
  {
    name: 'CabinSketch',
    fontFamily: 'CabinSketch',
  },
  {
    name: 'CaesarDressing',
    fontFamily: 'CaesarDressing-Regular'
  },
  {
    name: 'Carattere',
    fontFamily: 'Carattere'
  },
  {
    name: 'Cardo',
    fontFamily: 'Cardo',
  },

  {
    name: 'CarterOne',
    fontFamily: 'CarterOne',
  },
  {
    name: 'ChakraPetch-Bold',
    fontFamily: 'ChakraPetch-Bold',
  },
  {
    name: 'Chango',
    fontFamily: 'Chango',
  },
  {
    name: 'Creepster',
    fontFamily: 'Creepster'
  },
  {
    name: 'FredokaOne',
    fontFamily: 'FredokaOne',
  },
  {
    name: 'IrishGrover',
    fontFamily: 'IrishGrover',
  },
  {
    name: 'Lobster',
    fontFamily: 'Lobster',
  },
  {
    name: 'Nabla',
    fontFamily: 'Nabla',
  },
  {
    name: 'PatrickHand',
    fontFamily: 'PatrickHand',
  },
  {
    name: 'Peralta',
    fontFamily: 'Peralta',
  },
  {
    name: 'PressStart2P',
    fontFamily: 'PressStart2P',
  },
  {
    name: 'RammettoOne',
    fontFamily: 'RammettoOne',
  },

]

export default enList
