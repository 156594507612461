<template>
  <div class="right-btns">
    <div
      v-tooltip.bottom="'download'"
      class="btn download" @click="saveImg"></div>
  </div>
</template>

<script>
import select from "@/mixins/select";
import {v4 as uuid} from "uuid";

export default {
  name: "TopBtns",
  mixins: [select],
  methods: {
    saveImg() {
      const zoom = this.canvas.zoom
      const option = {name: 'New Image', format: 'png', quality: 1, multiplier: 2 / zoom}
      const dataUrl = this.canvas.c.toDataURL(option)
      this.downFile(dataUrl, 'png')
    },
    downFile(fileStr, fileType) {
      const anchorEl = document.createElement('a');
      anchorEl.href = fileStr;
      anchorEl.download = uuid() + '.' + fileType;
      document.body.appendChild(anchorEl); // required for firefox
      anchorEl.click();
      anchorEl.remove();
    },
  }
}
</script>

<style scoped lang="less">

.right-btns {
  display: flex;
  align-items: center;
  right: 0;
  height: 60px;

  .btn {
    width: 40px;
    height: 40px;
    cursor: pointer;
    margin-left: 15px;
    background-size: contain;
    background-repeat: no-repeat;
  }

  .download {
    background-image: url("../assets/header/download.png");

    &:hover {
      background-image: url("../assets/header/downloada.png");
    }
  }
}
</style>
