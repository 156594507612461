<template>
<div class="history">
  <div
    v-tooltip.bottom="'last step'"
    :class="{inactive: currentIndex <= 0}" class="btn last" @click.stop="undo"></div>
  <div
    v-tooltip.bottom="'next step'"
    :class="{inactive: currentIndex >= list.length - 1}" class="btn pre" @click.stop="redo"></div>
  <div
    class="loading-mask"
    @click="historyProcessing = false"
    v-if="historyProcessing">
    <img src="../assets/loading.gif" alt="">
  </div>
</div>
</template>

<script>
import select from '@/mixins/select'

const maxStep = 20
export default {
  name: "SetHistory",
  mixins: [select],
  data() {
    return {
      currentIndex: 0,
      redoList:[],
      list:[],
      historyProcessing: false
    };
  },
  created(){
    // record when update
    this.canvas.c.on({
      'object:added': this.save,
      'object:removed': this.save,
      'object:modified': this.save,
      'erasing:end': this.save
      // 'selection:updated': this.save,
    });
    this.save()
    this.currentIndex = 0
  },
  methods:{
    // 保存记录
    save() {
      if (this.historyProcessing) return

      const data = this.canvas.c.toJSON(['id', 'erasable'])
      if(this.list.length > maxStep){
        this.list.shift()
      }
      if (this.list.length > this.currentIndex + 1) {
        this.list = this.list.slice(0, this.currentIndex + 1)
      }
      this.list.push(data)
      this.currentIndex += 1
    },
    // 后退
    undo(){
      if(this.list.length >= 2 && this.currentIndex >= 1){
        // const currentState = this.list[this.currentIndex]
        // const preState = this.list[this.currentIndex - 1]
        // const o = this.list[this.currentIndex].objects[0]
        // console.log(o)
        // this.fabric.util.enlivenObjects([o], (enlivenedObject) => {
        //   this.canvas.c.add(enlivenedObject[0])
        //   this.canvas.c.renderAll()
        // })
        // const object = new this.fabric.Object(o)
        // console.log(object)
        this.renderCanvas(this.list[this.currentIndex - 1])
        this.currentIndex -= 1
      }
    },
    // 重做
    redo(){
      if(this.currentIndex < this.list.length - 1){
        this.renderCanvas(this.list[this.currentIndex + 1])
        this.currentIndex += 1
      }
    },
    rerender(target, current) {

    },
    renderCanvas(data){
      this.historyProcessing = true
      // this.canvas.c.loadFromJSON(data, this.canvas.c.renderAll.bind(this.canvas.c));
      // this.canvas.c.requestRenderAll();
      this.canvas.c.loadFromJSON(data, () => {
        this.canvas.c.renderAll();
        this.historyProcessing = false
      })
    }
  }
}
</script>

<style scoped lang="less">
.history {
  display: flex;
  align-items: center;
  margin-right: 10px;
}
.btn {
  width: 30px;
  height: 30px;
  background-image: url("../assets/history-btn.png");
  background-size: 15px;
  background-position: center;
  background-repeat: no-repeat;
  margin-right: 5px;
  cursor: pointer;
  &.pre {
    transform: rotateY(180deg);
  }
  &.inactive {
    opacity: 0.3;
  }
}
.loading-mask {
  background-color: rgba(0,0,0,0.6);
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 99999;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 100px;
    height: 100px;
  }
}

</style>
