<template>
  <div>
    <div class="title">Customize</div>
    <div class="custom-form">
      <input v-model="width" type="number" @change="setSize" class="custom-input">x
      <input v-model="height" type="number" @change="setSize" class="custom-input height">
    </div>

    <div class="title">Common</div>
    <div class="common-wrapper">
      <div
        :key="i"
        class="btn"
        v-for="(item, i) in presetSize"
        @click="setSizeBy(item.width, item.height, i)"
        :class="{active: activeIndex === i}"
      >
        <span class="label">{{item.label}}</span>
        <span class="size">{{item.width}}x{{item.height}}</span>
      </div>
    </div>
  </div>
</template>

<script>

const presetConfigSize = [{
  label: 'Square',
  width: 450,
  height: 450,
}, {
  label: 'Mid',
  width: 450,
  height: 600,
}, {
  label: 'Wallpaper',
  width: 828,
  height: 1792,
}, {
  label: 'Twitter banner',
  width: 1350,
  height: 450,
},
]
export default {
  name: 'canvasSize',
  inject: ['canvas', 'fabric'],
  data() {
    return {
      width: 450,
      height: 450,
      presetSize: [...presetConfigSize],
      activeIndex: 0
    };
  },
  created() {
    this.setSize()
  },
  methods: {
    setSizeBy(width, height, index) {
      const originWidth = this.canvas.c.getWidth()
      const originHeight = this.canvas.c.getHeight()

      const zoom = this.canvas.zoom
      this.canvas.c.setWidth(Math.floor(width * zoom));
      this.canvas.c.setHeight(Math.floor(height * zoom));
      this.canvas.c.renderAll()
      this.width = width
      this.height = height
      this.activeIndex = index

      this.$emit('moveFrame', {
        x: (originWidth - this.width * zoom) / 2,
        y: (originHeight - this.height * zoom) / 2
      })
    },
    setSize() {
      const originWidth = this.canvas.c.getWidth()
      const originHeight = this.canvas.c.getHeight()

      const zoom = this.canvas.zoom
      this.canvas.c.setWidth(Math.floor(this.width * zoom));
      this.canvas.c.setHeight(Math.floor(this.height * zoom));
      this.canvas.c.renderAll()

      this.$emit('moveFrame', {
        x: (originWidth - this.width * zoom) / 2,
        y: (originHeight - this.height * zoom) / 2
      })
    }
  }
};
</script>

<style scoped lang="less">
.title {
  font-family: Montserrat-SemiBold, Montserrat;
  font-size: 16px;
  font-weight: 600;
  color: #000000;
  line-height: 20px;
  margin-bottom: 14px;
}

.custom-form {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-family: Montserrat-SemiBold, Montserrat;
  font-weight: 600;
  color: #000000;
  line-height: 50px;
  margin-bottom: 25px;

  .custom-input {
    width: 70px;
    height: 50px;
    background: #F8F8F8;
    border: none;
    border-radius: 11px;
    text-align: center;
    margin-right: 4px;

    &.height {
      margin-left: 4px;
    }
  }
}
.common-wrapper {
  width: 252px;
  background: #FAFAFA;
  border-radius: 18px;
  padding: 10px;

  .btn {
    height: 42px;
    margin-bottom: 10px;
    display: flex;
    padding: 0 12px;
    align-items: center;
    justify-content: space-between;
    font-family: Montserrat-Medium, Montserrat;
    font-weight: 500;
    cursor: pointer;
    border-radius: 1px;
    align-items: center;

    &.active {
      background: #EFEFEF;
    }

    .label {
      font-size: 14px;
      color: #000000;
      line-height: 20px;
    }

    .size {
      font-size: 14px;
      color: #818181;
      line-height: 20px;
    }
  }
}
</style>
