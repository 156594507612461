<template>
  <section>
    <div class="upload-btn" @click="triggerUpload">
      <input type="file" accept="image/*" ref="file" @change="uploadImage" />
      <img src="../assets/upload.png" alt="">
      Upload Image
    </div>
    <div class="search-wrapper">
      <input
        @keyup.enter="search"
        v-model="searchInput"
        type="text"
        class="search-input"
        placeholder="Search Google">
      <img class="search-icon" src="../assets/icons/search.png" alt="">
      <div v-show="searchInput !== ''" class="close-btn" @click="clearSearch"></div>
    </div>
    <div class="menu">
      <div
        class="tab"
        v-for="item in menus"
        :key="item"
        @click="activeMenu = item"
        :class="{active: activeMenu === item}"
      >{{ item }}</div>
      <div class="custom-scroll search-history">
        <div
          class="history-tab tab"
          v-for="item in searchHistory"
          :key="item"
          @click="searchStr(item)"
          :class="{active: searchInput === item}"
        >{{ item }}
          <div class="rm-history" @click="rmHistory(item)"></div>
        </div>
      </div>
    </div>
    <div
      @scroll="handleScroll"
      class="custom-scroll"
      v-show="searchResult.length > 0">
      <p class="search-title">Result</p>
      <div class="search-box">
        <img
          class="search-image"
          @click="addItem"
          v-for="item in searchResult"
          :src="item.link"
          :key="item.link"
          alt=""
          :data-tiny="item.tiny"
        >
      </div>
    </div>
    <div class="custom-scroll" v-show="searchResult.length <= 0">
      <div class="box" v-show="activeMenu === 'All'">
        <div
          :class="{open: foldState[index]}"
          class="series-wrapper"
          v-for="(item, index) in s3components"
          :key="item.id">
          <div class="series-header" @click="flipState(index)">
            <span>{{item.name}}</span>
            <div class="folder-btn" :class="{active: foldState[index]}"></div>
          </div>
          <div
            :style="{columns: item.columns}"
            class="images-wrapper"
            v-if="foldState[index]">
            <img
              :src="image"
              alt=""
              :key="index"
              v-for="(image, index) in item.images"
              @click="addItem">
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import select from '@/mixins/select'
import { v4 as uuid } from 'uuid';
import { getImgStr } from "@/utils/utils";
import components from '@/utils/components'
import axios from "axios";
import _ from 'lodash'
import request from '@/utils/request'

const defaultPosition = {
  left: 100, top: 100,  shadow: '', fontFamily: '1-1'
}
export default {
  name: 'ToolBar',
  data() {
    return {
      foldState: [],
      activeMenu: 'All',
      menus: ['All'],
      count: 0,
      arr: [],
      searchInput: '',
      searchResult: [],
      page: 0,
      searchHistory: ['elon musk', 'doge'],
      components,
      s3components: []
    }
  },
  watch: {
    searchInput: _.debounce(async function(val) {
      if (val === '') {
        this.searchResult = []
        return
      }
    }, 500)
  },
  mixins: [select],
  created(){
    for (let i = 0; i<this.components.length;i++) {
      this.foldState.push(false)
    }
    this.$set(this.foldState, 0, true);

    this.getComponents()

  },
  methods: {
    async getComponents() {
      const res = await request.get('/components?populate=*')
      // const res = await request.get('/nfts?populate=*')
      this.s3components = res.data.data.map(item => {
        const temp = item.attributes
        const images = temp.images.data?.map(image => image.attributes.url) || []
        return {
          columns: temp.columns,
          name: temp.name,
          id: item.id,
          images,
          order: temp.order || 0
        }
      }).filter(item => item.images.length > 0).sort((a, b) => b.id - a.id).sort((a, b) => b.order - a.order)
    },
    changeColor() {
      const obj = this.canvas.c.getActiveObjects()[0]
      const color = '#00ff00'
      console.log(obj)
      if (obj && obj._objects) {
        for (var i = 0; i < obj._objects.length; i++) {
          console.log(obj._objects[i].fill)
          if (obj._objects[i].fill === '#ff8752') {
            obj._objects[i].set({
              fill: color
            });
          }
        }
      }
      this.canvas.c.renderAll()

    },
    addSVG(e) {

      const url = e.target.src
      console.log(url)
      this.fabric.loadSVGFromURL(url, (objects, options) => {
        var item = this.fabric.util.groupSVGElements(objects, {...options, ...defaultPosition,
          id: uuid(),
          name: 'svg元素'
        });
        this.canvas.c.add(item)
        this.canvas.c.renderAll()
      });
    },
    async searchStr(val) {
      this.searchInput = val
      console.log(val)
      await this.loadImage(true)
    },
    rmHistory(val) {
      const valIndex = this.searchHistory.indexOf(val)
      if (valIndex >= 0) {
        this.searchHistory.splice(valIndex, 1)
      }
      if (val === this.searchInput) {
        this.searchInput = ''
      }
    },
    search: _.debounce(async function() {
      if (this.searchInput === '') {
        this.searchResult = []
        return
      }
      const valIndex = this.searchHistory.indexOf(this.searchInput)
      if (valIndex >= 0) {
        this.searchHistory.splice(valIndex, 1)
      }
      this.searchHistory.unshift(this.searchInput)
      await this.loadImage(true)
      for (let i = 0; i < 3; i++) {
        await this.loadImage(false)
      }
    }),
    async loadImage(refresh) {
      const searchWord = this.searchInput
      if (refresh) {
        this.page = 0
        this.searchResult = []
      } else {
        this.page += 1
      }
      const key = 'AIzaSyAtPOIRiDzWgeY9T_bqpXrgtb6hi7yjJNg'
      const cx = '66231a98d4c2f48e7'
      const url = `https://www.googleapis.com/customsearch/v1?key=${key}&cx=${cx}&q=${searchWord}&searchType=image&start=${this.page*10 + 1}`
      // https://www.googleapis.com/customsearch/v1?key=INSERT_YOUR_API_KEY&cx=017576662512468239146:omuauf_lfve&q=lectures
      // AIzaSyAtPOIRiDzWgeY9T_bqpXrgtb6hi7yjJNg
      const res = await axios.get(url)
      const tempResult = res.data.items.map(item => {
        return {
          link: item.link,
          tiny: item.image.thumbnailLink
        }
      })
      this.searchResult = this.searchResult.concat(tempResult)
    },
    clearSearch() {
      this.searchInput = ''

    },
    flipState(index) {
      this.$set(this.foldState, index, !this.foldState[index])
    },
    getIndex(start, end){
      const arr = Array(end - (start - 1)).fill('')
      return arr.map((item, i) => (i + start))
    },
    // 按照类型渲染
    addItem(e) {
      this.canvas.c.fire('loading:show')
      let img = document.createElement('img');
      img.setAttribute('crossorigin', 'anonymous');

      img.src = e.target.src + '?not-from-cache-please';
      img.onload = () => {
        const width = img.width / 2
        const height = img.height / 2
        const canvasWidth = this.canvas.c.getWidth()
        const canvasHeight = this.canvas.c.getHeight()
        const zoom = this.canvas.zoom
        const imgInstance = new this.fabric.Image(img, {
          id: uuid(),
          name: 'image' + this.count,
          left: (canvasWidth / zoom - width) / 2, top: (canvasHeight / zoom - height) / 2,
          src: img.src,
          erasable: false
        });
        this.count += 1;
        // 设置缩放
        imgInstance.scale(0.5);
        this.canvas.c.add(imgInstance)
        this.canvas.c.setActiveObject(imgInstance);
        this.canvas.c.renderAll()
        this.canvas.c.fire('loading:end')
      }
      // img.onerror = () => {
      //   const bakUrl = e.target.dataset.tiny + '?not-from-cache-please';
      //   if (img.src !== bakUrl) {
      //     img.src = bakUrl
      //   }
      // }
    },
    handleScroll: _.debounce(async function(event) {
      const element = event.target;

      if (element.scrollHeight - element.scrollTop === element.clientHeight) {
        this.loadImage(false)
      }
    }, 500),
    triggerUpload() {
      this.$refs.file.click();
    },
    uploadImage() {
      const imageFile = this.$refs.file.files[0]
      getImgStr(imageFile).then(res => {
        const image = res;
        const imgEl = document.createElement('img');
        imgEl.src = image
        document.body.appendChild(imgEl);
        imgEl.onload = () => {
          // create image object
          const imgInstance = new this.fabric.Image(imgEl, {
            id: uuid(),
            name: 'uploadImage' + this.uploadCount,
            left: 100, top: 100,
            src: image,
            file: imageFile,
            erasable: false
          });
          this.uploadCount += 1
          imgInstance.scale(0.5);
          this.canvas.c.add(imgInstance)
          this.canvas.c.setActiveObject(imgInstance);
          this.canvas.c.renderAll()
          // remove image element
          imgEl.remove()
        }
      })
    }

  }
};
</script>

<style scoped lang="less">
section {
  padding-top: 2.9rem;
}
.upload-btn {
  width: 210px;
  height: 48px;
  margin: 0 38px 0 auto;
  background: #E9E9E9;
  border-radius: 25px;
  font-size: 16px;
  font-family: Montserrat-SemiBold, Montserrat;
  font-weight: 600;
  color: #000000;
  line-height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 24px;
  cursor: pointer;
  transition-duration: 0.3s;
  &:hover {
    transform: scale(1.05);
  }
  img {
    width: 20px;
    height: 20px;
    margin-right: 6px;
    margin-bottom: 0;
  }
  input {
    width: 0;
    height: 0;
    visibility: hidden;
  }

}
.search-wrapper {
  margin-bottom: 24px;
  position: relative;
  .search-icon, .close-btn {
    position: absolute;
  }
  .search-icon  {
    width: 20px;
    height: 20px;
    left: 18px;
    top: 15px;
    opacity: 0.5;
  }
  .close-btn {
    width: 30px;
    height: 30px;
    background-image: url("../assets/icons/close.png");
    background-size: 14px;
    background-position: center;
    background-repeat: no-repeat;
    cursor: pointer;
    right: 30px;
    top: 10px;
  }
  .search-input {
    position: relative;
    width: 252px;
    height: 50px;
    border-radius: 18px 18px 18px 18px;
    opacity: 1;
    border: 1px solid #E7E7E7;
    text-indent: 46px;
    line-height: 50px;
    outline: none;
    font-size: 16px;
    font-family: Montserrat-Medium, Montserrat;
    font-weight: 500;
    color: #000000;

    &:focus {
      border: 1px solid #000000;
    }
  }
}
.box {
  width: 252px;
  display: flex;
  flex-direction: column;

  .series-wrapper {

    .series-header {
      cursor: pointer;
      margin-bottom: 15px;
      position: relative;
      display: flex;
      justify-content: space-between;
      align-items: center;
      span {
        height: 17px;
        font-size: 14px;
        font-family: Montserrat-SemiBold, Montserrat;
        font-weight: 600;
        color: #000000;
        line-height: 17px;
      }
      .folder-btn {
        cursor: pointer;
        position: absolute;
        background-image: url("../assets/arrow-up.png");
        right: 9px;
        width: 21px;
        height: 21px;
        background-size: 14px;
        background-repeat: no-repeat;
        background-position: center;
        transform: rotate(180deg);
        transition-duration: 0.2s;

        &.active {
          transform: rotate(0);
        }
      }
    }
    .images-wrapper {
      columns: 3;
      column-gap: 10px;
      //display: none;
    }

    //&.open {
    //  .images-wrapper {
    //    display: block;
    //  }
    //}
  }
}

.custom-scroll {
  overflow-y: scroll;
  height: calc(100vh - 334px);
  .search-box {
    display: flex;
    flex-flow: wrap;
  }
  .search-image {
    width: auto;
    height: 60px;
    margin-right: 10px;
  }
}
img {
  break-inside: avoid;
  display: inline-block;
  width: 100%;
  //width: calc(100% / 3 - 10px);
  object-fit: cover;
  margin-bottom: 10px;
  background: #F5F5F5;
  border-radius: 10px;
  cursor: pointer;
  counter-reset: items;
}


.menu {
  display: flex;
  border-bottom: none;
  margin-bottom: 2.5rem;

  .tab {
    cursor: pointer;
    font-size: 14px;
    font-family: Montserrat-Medium, Montserrat;
    font-weight: 500;
    color: #969696;
    line-height: 26px;
    height: 26px;
    padding: 0 16px;
    border-radius: 26px;

    &.active {
      font-family: Montserrat-Bold, Montserrat;
      font-weight: bold;
      background: #EEFFF0;
      color: #60C96F;
    }
  }

  .search-history {
    display: flex;
    flex-shrink: 0;
    margin-left: 10px;
    overflow-x: scroll;
    overflow-y: visible;
    height: 32px;
    width: 200px;
  }

  .history-tab {
    background: #F7F7F7;
    margin-right: 10px;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: ceter;
    padding-right: 4px;

    .rm-history {
      width: 26px;
      height: 26px;
      background-image: url("../assets/close.png");
      background-position: center;
      background-size: 14px;
      background-repeat: no-repeat;
      cursor: pointer;
      opacity: 0.6;
    }
  }
}

.search-title {
  font-size: 14px;
  font-family: Montserrat-SemiBold, Montserrat;
  font-weight: 600;
  color: #000000;
  line-height: 16px;
  margin-bottom: 15px;
  span {
    margin-left: 8px;
    font-size: 14px;
    font-family: Montserrat-Medium, Montserrat;
    color: #969696;
    line-height: 16px;
  }
}
</style>
