import hotkeys from 'hotkeys-js';

export default function (canvas) {
  hotkeys('left,right,down,up', function (event, handler) {
    const activeObject = canvas.getActiveObject();
    if (activeObject) {
      switch (handler.key) {
        case 'left':
          activeObject.set('left', activeObject.left - 1)
          break;
        case 'right':
          activeObject.set('left', activeObject.left + 1)
          break;
        case 'down':
          activeObject.set('top', activeObject.top + 1)
          break;
        case 'up':
          activeObject.set('top', activeObject.top - 1)
          break;
        default:
      }
      canvas.renderAll()
    }
  });

  hotkeys('esc', function () {
    const id = localStorage.getItem('activeId')
    canvas.isDrawingMode = false
    canvas.fire('mode:normal')
    canvas.requestRenderAll()
    canvas.discardActiveObject()
    canvas.forEachObject((o) => {
      if (o.id && o.id === id) {
        o.set('erasable', false);
        canvas.setActiveObject(o)
        canvas.renderAll()
      }
    })
  })

  hotkeys('backspace', function (event, handler) {
    const activeObject = canvas.getActiveObjects();
    if (activeObject) {
      activeObject.map(item => canvas.remove(item))
      canvas.requestRenderAll()
      canvas.discardActiveObject()
    }
  });
}
