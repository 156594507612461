import { render, staticRenderFns } from "./AddNft.vue?vue&type=template&id=60b97935&scoped=true&"
import script from "./AddNft.vue?vue&type=script&lang=js&"
export * from "./AddNft.vue?vue&type=script&lang=js&"
import style0 from "./AddNft.vue?vue&type=style&index=0&id=60b97935&prod&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "60b97935",
  null
  
)

export default component.exports