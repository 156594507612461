export default [
  {
    name: 'MEGA',
    images: [
      "https://dogc.s3.amazonaws.com/images/banana/mega1.png",
      "https://dogc.s3.amazonaws.com/images/banana/mega2.png",
      "https://dogc.s3.amazonaws.com/images/banana/mega3.png",
      "https://dogc.s3.amazonaws.com/images/banana/mega4.png",
      "https://dogc.s3.amazonaws.com/images/banana/mega5.png",
      "https://dogc.s3.amazonaws.com/images/banana/mega6.png",
      "https://dogc.s3.amazonaws.com/images/banana/mega7.png",
    ]
  },
  {
    name: 'Background',
    images: [
      "https://dogc.s3.amazonaws.com/images/christmasmeme/0.png",
      "https://dogc.s3.amazonaws.com/images/christmasmeme/1.png",
      "https://dogc.s3.amazonaws.com/images/christmasmeme/2.png",
      "https://dogc.s3.amazonaws.com/images/christmasmeme/3.png",
      "https://dogc.s3.amazonaws.com/images/christmasmeme/4.png",
      "https://dogc.s3.amazonaws.com/images/christmasmeme/5.png",
      "https://dogc.s3.amazonaws.com/images/christmasmeme/6.png",
      "https://dogc.s3.amazonaws.com/images/christmasmeme/7.png",
      "https://dogc.s3.amazonaws.com/images/christmasmeme/8.png",
    ],
    columns: 1
  },
  {
    name: 'New Year',
    images: [
      'https://dogc.s3.amazonaws.com/images/meme2/2-2-001.png',
      'https://dogc.s3.amazonaws.com/images/meme2/2-2-002.png',
      'https://dogc.s3.amazonaws.com/images/meme2/2-2-003.png',
      'https://dogc.s3.amazonaws.com/images/meme2/2-2-004.png',
      'https://dogc.s3.amazonaws.com/images/meme2/2-2-005.png',
      'https://dogc.s3.amazonaws.com/images/meme2/2-2-006.png',
      'https://dogc.s3.amazonaws.com/images/meme2/2-2-007.png',
      'https://dogc.s3.amazonaws.com/images/meme2/2-2-008.png',
      'https://dogc.s3.amazonaws.com/images/meme2/2-2-009.png',
      'https://dogc.s3.amazonaws.com/images/meme2/2-2-010.png',
    ]
  },
  {
    name: 'Adventure',
    images: [
      'https://dogc.s3.amazonaws.com/images/meme2/2-2-13.png',
      'https://dogc.s3.amazonaws.com/images/meme2/2-2-14.png',
      'https://dogc.s3.amazonaws.com/images/meme2/2-2-16.png',
      'https://dogc.s3.amazonaws.com/images/meme2/2-2-17.png',
      'https://dogc.s3.amazonaws.com/images/meme2/2-2-18.png',
      'https://dogc.s3.amazonaws.com/images/meme2/2-2-19.png',
      'https://dogc.s3.amazonaws.com/images/meme2/2-2-20.png',
      'https://dogc.s3.amazonaws.com/images/meme2/2-2-21.png',
      'https://dogc.s3.amazonaws.com/images/meme2/2-2-22.png',
      'https://dogc.s3.amazonaws.com/images/meme2/2-3-1.png',
      'https://dogc.s3.amazonaws.com/images/meme2/2-3-2.png',
      'https://dogc.s3.amazonaws.com/images/meme2/2-3-3.png',
      'https://dogc.s3.amazonaws.com/images/meme2/2-3-4.png',
      'https://dogc.s3.amazonaws.com/images/meme2/2-3-5.png',
      'https://dogc.s3.amazonaws.com/images/meme2/2-3-6.png',
      'https://dogc.s3.amazonaws.com/images/meme2/2-3-7.png',
      'https://dogc.s3.amazonaws.com/images/meme2/2-3-8.png',
      'https://dogc.s3.amazonaws.com/images/meme2/2-3-9.png',
      'https://dogc.s3.amazonaws.com/images/meme2/2-3-10.png',
      'https://dogc.s3.amazonaws.com/images/meme2/2-3-11.png',
      'https://dogc.s3.amazonaws.com/images/meme2/2-3-12.png',
      'https://dogc.s3.amazonaws.com/images/meme2/2-3-13.png',
      'https://dogc.s3.amazonaws.com/images/meme2/2-3-14.png',
      'https://dogc.s3.amazonaws.com/images/meme2/2-3-15.png',
      'https://dogc.s3.amazonaws.com/images/meme2/2-3-16.png',
    ]
  },
  {
    name: 'Frame',
    images: [
      'https://dogc.s3.amazonaws.com/images/meme2/2-1-001.png',
      'https://dogc.s3.amazonaws.com/images/meme2/2-1-002.png',
      'https://dogc.s3.amazonaws.com/images/meme2/2-1-003.png',
      'https://dogc.s3.amazonaws.com/images/meme2/2-1-004.png',
      "https://dogc.s3.amazonaws.com/images/christmasmeme/40.png",
      "https://dogc.s3.amazonaws.com/images/christmasmeme/41.png",
      "https://dogc.s3.amazonaws.com/images/christmasmeme/42.png",
      "https://dogc.s3.amazonaws.com/images/christmasmeme/43.png",
      "https://dogc.s3.amazonaws.com/images/christmasmeme/44.png",
      "https://dogc.s3.amazonaws.com/images/christmasmeme/45.png",
      "https://dogc.s3.amazonaws.com/images/christmasmeme/46.png",
      "https://dogc.s3.amazonaws.com/images/christmasmeme/47.png",
      "https://dogc.s3.amazonaws.com/images/christmasmeme/48.png",
      "https://dogc.s3.amazonaws.com/images/christmasmeme/49.png",
    ],
    columns: 3
  },
  {
    name: 'Christmas',
    images: [
      "https://dogc.s3.amazonaws.com/images/christmasmeme/0.png",
      "https://dogc.s3.amazonaws.com/images/christmasmeme/1.png",
      "https://dogc.s3.amazonaws.com/images/christmasmeme/2.png",
      "https://dogc.s3.amazonaws.com/images/christmasmeme/3.png",
      "https://dogc.s3.amazonaws.com/images/christmasmeme/4.png",
      "https://dogc.s3.amazonaws.com/images/christmasmeme/5.png",
      "https://dogc.s3.amazonaws.com/images/christmasmeme/6.png",
      "https://dogc.s3.amazonaws.com/images/christmasmeme/7.png",
      "https://dogc.s3.amazonaws.com/images/christmasmeme/8.png",
      "https://dogc.s3.amazonaws.com/images/christmasmeme/9.png",
      "https://dogc.s3.amazonaws.com/images/christmasmeme/10.png",
      "https://dogc.s3.amazonaws.com/images/christmasmeme/11.png",
      "https://dogc.s3.amazonaws.com/images/christmasmeme/12.png",
      "https://dogc.s3.amazonaws.com/images/christmasmeme/13.png",
      "https://dogc.s3.amazonaws.com/images/christmasmeme/14.png",
      "https://dogc.s3.amazonaws.com/images/christmasmeme/15.png",
      "https://dogc.s3.amazonaws.com/images/christmasmeme/16.png",
      "https://dogc.s3.amazonaws.com/images/christmasmeme/17.png",
      "https://dogc.s3.amazonaws.com/images/christmasmeme/18.png",
      "https://dogc.s3.amazonaws.com/images/christmasmeme/19.png",
      "https://dogc.s3.amazonaws.com/images/christmasmeme/20.png",
      "https://dogc.s3.amazonaws.com/images/christmasmeme/21.png",
      "https://dogc.s3.amazonaws.com/images/christmasmeme/22.png",
      "https://dogc.s3.amazonaws.com/images/christmasmeme/23.png",
      "https://dogc.s3.amazonaws.com/images/christmasmeme/24.png",
      "https://dogc.s3.amazonaws.com/images/christmasmeme/25.png",
      "https://dogc.s3.amazonaws.com/images/christmasmeme/26.png",
      "https://dogc.s3.amazonaws.com/images/christmasmeme/27.png",
      "https://dogc.s3.amazonaws.com/images/christmasmeme/28.png",
      "https://dogc.s3.amazonaws.com/images/christmasmeme/29.png",
      "https://dogc.s3.amazonaws.com/images/christmasmeme/30.png",
      "https://dogc.s3.amazonaws.com/images/christmasmeme/31.png",
      "https://dogc.s3.amazonaws.com/images/christmasmeme/32.png",
      "https://dogc.s3.amazonaws.com/images/christmasmeme/33.png",
      "https://dogc.s3.amazonaws.com/images/christmasmeme/34.png",
      "https://dogc.s3.amazonaws.com/images/christmasmeme/35.png",
      "https://dogc.s3.amazonaws.com/images/christmasmeme/36.png",
      "https://dogc.s3.amazonaws.com/images/christmasmeme/37.png",
      "https://dogc.s3.amazonaws.com/images/christmasmeme/38.png",
      "https://dogc.s3.amazonaws.com/images/christmasmeme/39.png",
      "https://dogc.s3.amazonaws.com/images/christmasmeme/40.png",
      "https://dogc.s3.amazonaws.com/images/christmasmeme/41.png",
      "https://dogc.s3.amazonaws.com/images/christmasmeme/42.png",
      "https://dogc.s3.amazonaws.com/images/christmasmeme/43.png",
      "https://dogc.s3.amazonaws.com/images/christmasmeme/44.png",
      "https://dogc.s3.amazonaws.com/images/christmasmeme/45.png",
      "https://dogc.s3.amazonaws.com/images/christmasmeme/46.png",
      "https://dogc.s3.amazonaws.com/images/christmasmeme/47.png",
      "https://dogc.s3.amazonaws.com/images/christmasmeme/48.png",
      "https://dogc.s3.amazonaws.com/images/christmasmeme/49.png",
      "https://dogc.s3.amazonaws.com/images/christmasmeme/50.png",
      "https://dogc.s3.amazonaws.com/images/christmasmeme/51.png",
      "https://dogc.s3.amazonaws.com/images/christmasmeme/52.png",
      "https://dogc.s3.amazonaws.com/images/christmasmeme/53.png",
      "https://dogc.s3.amazonaws.com/images/christmasmeme/54.png",
      "https://dogc.s3.amazonaws.com/images/christmasmeme/55.png",
      "https://dogc.s3.amazonaws.com/images/christmasmeme/56.png",
      "https://dogc.s3.amazonaws.com/images/christmasmeme/57.png",
      "https://dogc.s3.amazonaws.com/images/christmasmeme/58.png",
      "https://dogc.s3.amazonaws.com/images/christmasmeme/59.png",
      "https://dogc.s3.amazonaws.com/images/christmasmeme/60.png",
      "https://dogc.s3.amazonaws.com/images/christmasmeme/61.png",
      "https://dogc.s3.amazonaws.com/images/christmasmeme/62.png",
      "https://dogc.s3.amazonaws.com/images/christmasmeme/63.png"
    ]
  },
  {
    name: 'Banana',
    images: [
      'https://dogc.s3.amazonaws.com/images/meme2/2-2-11.png',
      'https://dogc.s3.amazonaws.com/images/meme2/2-2-12.png',
      'https://dogc.s3.amazonaws.com/images/meme2/2-2-15.png',
      'https://dogc.s3.amazonaws.com/images/banana/0.png',
      'https://dogc.s3.amazonaws.com/images/banana/1.png',
      'https://dogc.s3.amazonaws.com/images/banana/2.png',
      'https://dogc.s3.amazonaws.com/images/banana/3.png',
      'https://dogc.s3.amazonaws.com/images/banana/4.png',
      'https://dogc.s3.amazonaws.com/images/banana/5.png',
      'https://dogc.s3.amazonaws.com/images/banana/6.png',
      'https://dogc.s3.amazonaws.com/images/banana/7.png',
      'https://dogc.s3.amazonaws.com/images/banana/8.png',
      'https://dogc.s3.amazonaws.com/images/banana/9.png',
      'https://dogc.s3.amazonaws.com/images/banana/10.png',
      'https://dogc.s3.amazonaws.com/images/banana/11.png',
    ]
  }
]
