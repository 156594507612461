<template>
  <section class="Header">
    <div class="left">
      <img src="../assets/applogo.png" class="logo" alt="">
      <div class="beta">Beta 0.1</div>
      <a href="/" class="to-index"></a>
    </div>

    <div class="right">
      <ConnectWallet/>
    </div>
  </section>
</template>

<script>
import ConnectWallet from "@/components/ConnectWallet";

export default {
  name: "AppHeader",
  components: {
    ConnectWallet
  },
}
</script>

<style scoped lang="less">
section {
  width: 100%;
  height: 60px;
  background: #FFFFFF;
  box-shadow: 0 4px 10px 0 rgba(233, 233, 233, 0.25);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  position: relative;
  z-index: 2;
  .left {
    display: flex;
    position: relative;
    align-items: center;
    .to-index {
      position: absolute;
      left: 0;
      top: 0;
      width: 63px;
      height: 100%;
    }
  }

  .logo {
    width: 63px;
    margin-right: 18px;
  }

  .beta {
    width: 63px;
    height: 21px;
    background: #373737;
    border-radius: 30px 30px 30px 30px;
    font-size: 12px;
    font-family: Montserrat-Medium, Montserrat;
    font-weight: 500;
    color: #FFFFFF;
    line-height: 21px;
    text-align: center;
  }
}

</style>
