import Vue from 'vue'
import Vuex from 'vuex'


Vue.use(Vuex)

export default new Vuex.Store({
  mutations: {
    SetAddress(state, val) {
      state.address = val
    },
    SetSign(state, val) {
      state.sign = val
    },
    SetToken(state, val) {
      state.token = val
    }
  },
  state: {
    address: '',
    sign: '',
    token: ''
  }
})
