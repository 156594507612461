<template>
  <div class="zoom-btn">
    <span
      :class="{'show-option': showZoom}"
      class="zoom-rate"
      @click.stop="showZoomSwitch">{{currentZoom}}%</span>
    <div class="zoom-options" v-if="showZoom">
      <div class="option" @click="zoom(1)">Zoom In</div>
      <div class="option" @click="zoom(-1)">Zoom Out</div>
      <hr class="line">
      <div
        @click="changeZoom(item)"
        :key="item"
        class="option"
        v-for="item in zoomRate">
        {{ item }}%
      </div>
      <hr class="line">
      <div class="option" @click="sizeByScreen(80)">Contain</div>
      <div class="option" @click="sizeByScreen(100)">Cover</div>
    </div>
  </div>
</template>

<script>
const zoomRate = [400, 200, 150, 100, 75, 50, 25, 10]

export default {
  name: "ZoomFrame",
  inject: ['canvas', 'fabric'],
  data() {
    return {
      // zoom
      zoomRate,
      currentZoom: 100,
      showZoom: false
    }
  },
  watch: {
    currentZoom: function(value) {
      let rate = value / 100
      const zoom = this.canvas.zoom
      this.canvas.c.setZoom(rate)
      this.canvas.c.renderAll();
      const tempWidth = this.canvas.c.getWidth() / zoom
      const tempHeight = this.canvas.c.getHeight() / zoom
      this.canvas.c.setWidth(tempWidth * rate)
      this.canvas.c.setHeight(tempHeight * rate)
      this.canvas.zoom = rate
      this.$emit('fix')
    }
  },
  mounted() {
    this.sizeByScreen(80)
  },
  methods: {
    showZoomSwitch(e) {
      this.showZoom = true
      document.addEventListener('click',this.closeZoomSwitch)
    },
    closeZoomSwitch() {
      this.showZoom = false
      document.removeEventListener('click', this.closeZoomSwitch)
    },
    zoom(direction) {
      const target = Math.round(this.currentZoom + direction * 10)
      console.log(target)
      if (target >= 10 && target <= 400) {
        this.currentZoom = target
      } else if (target < 10) {
        this.currentZoom = 10
      } else {
        this.currentZoom = 400
      }
      this.changeZoom(this.currentZoom)
    },
    changeZoom(percent) {
      this.currentZoom = percent
    },
    sizeByScreen(percent) {
      const zoom = this.canvas.zoom
      const tempWidth = this.canvas.c.getWidth() / zoom
      const tempHeight = this.canvas.c.getHeight() / zoom

      const screenWidth = window.innerWidth - 642
      const screenHeight = window.innerHeight - 160

      this.currentZoom = Math.round(Math.min(screenWidth * percent / tempWidth, screenHeight * percent / tempHeight))
    }
  }
}
</script>

<style scoped lang="less">
.zoom-btn {
  height: 60px;
  width: 80px;
  position: relative;
  display: flex;
  align-items: center;
  .zoom-rate {
    display: block;
    width: 80px;
    height: 34px;
    background: #F8F8F8;
    border-radius: 10px;
    cursor: pointer;
    font-size: 14px;
    font-family: Montserrat-Medium, Montserrat;
    font-weight: 500;
    color: #000000;
    line-height: 34px;
    position: relative;
    text-indent: 12px;
    &:after {
      background-repeat: no-repeat;
      background-size: contain;
      content: ' ';
      width: 11px;
      height: 9px;
      position: absolute;
      top: 12px;
      right: 11px;
      background-image: url("../assets/layer/arrowh.png");
      transform: rotate(180deg);
      transition-duration: 0.3s;
    }
    &.show-option:after {
      transform: rotate(0);
    }
  }
  .zoom-options {
    width: 145px;
    left: 50%;
    margin-left: -72px;
    top: 60px;
    background: #FFFFFF;
    box-shadow: 0 4px 15px 0 rgba(209,209,209,0.25);
    border-radius: 10px;
    padding: 5px 0;
    position: absolute;
    .option {
      margin: 0 5px;
      width: 134px;
      height: 34px;
      border-radius: 10px;
      font-size: 14px;
      font-family: Montserrat-Medium, Montserrat;
      font-weight: 500;
      color: #333333;
      line-height: 34px;
      padding: 0 10px;
      cursor: pointer;

      &:hover {
        background: #F8F8F8;
      }
    }
    .line {
      border: 1px solid #EDEDED;
      border-bottom: none;
      margin: 5px 0;
    }
  }

}</style>
