<template>
  <section>
    <div class="add-btn" @click="addText">
      <img src="../assets/add.png" alt="">
      Add Text
    </div>
    <p class="title">Template</p>
    <div class="template-wrapper">
      <div
        class="template"
        :key="index"
        :style="{
          fontFamily: item.fontFamily,
          color: item.color,
          fontSize: item.fontSize + 'px',
          lineHeight: item.lineHeight + 'px'
         }"
        @click="addTemplate(index)"
        v-for="(item, index) in templates"
      >{{item.content}}</div>
    </div>
  </section>
</template>

<script>
import select from "@/mixins/select";
import {v4 as uuid} from "uuid";
const defaultPosition = {
  left: 100, top: 100, shadow: '', fontFamily: 'AlfaSlabOne'
}
export default {
  name: "AddText",
  mixins: [select],
  data() {
    return {
      templates: [{
        fontFamily: 'Peralta',
        content: 'BOOM',
        color: '#F5C64A',
        fontSize: 18
      }, {
        fontFamily: 'IrishGrover',
        content: 'GM & GN',
        color: '#393E48',
        fontSize: 18
      }, {
        fontFamily: 'Creepster',
        content: 'COMING\nSOON',
        color: '#000000',
        fontSize: 23,
        lineHeight: 25
      }, {
        fontFamily: 'Chango',
        content: 'Hello\nDOGE',
        color: '#55A475',
        fontSize: 23,
        lineHeight: 25
      }, {
        fontFamily: 'Chango',
        content: 'LOVE\nIT',
        color: '#3A38BC',
        fontSize: 23,
        lineHeight: 25
      }, {
        fontFamily: 'CaesarDressing',
        content: 'COMING\nSOON',
        color: '#000000',
        fontSize: 23,
        lineHeight: 25
      }, {
        fontFamily: 'Carattere',
        content: 'Thank\nYou',
        color: '#1D377B',
        fontSize: 23,
        lineHeight: 25
      }, {
        fontFamily: 'ChakraPetch-Bold',
        content: 'FOMO',
        color: '#282828',
        fontSize: 23,
        lineHeight: 25
      }]
    }
  },
  methods: {
    addText() {
      const text = new this.fabric.IText('Hello Doge!', {
        ...defaultPosition,
        lockScalingX: true,
        lockScalingY: true,
        fontSize: 40, id: uuid(),
        onSelect: this.showAttr,
        erasable: false
      });
      this.canvas.c.add(text)
      this.canvas.c.setActiveObject(text);
    },
    addTemplate(index) {
      const template = this.templates[index]
      const text = new this.fabric.IText(template.content, Object.assign({}, defaultPosition, {
        fontFamily: template.fontFamily,
        fill: template.color,
        lockScalingX: true,
        lockScalingY: true,
        fontSize: template.fontSize, id: uuid(),
        onSelect: this.showAttr,
        textAlign: 'center',
        lineHeight: 1,
        erasable: false
      }))
      this.canvas.c.add(text)
      this.canvas.c.setActiveObject(text);
    },
    showAttr() {
      this.$emit('showAttr')
    }
  }

}
</script>

<style scoped lang="less">
section {
  padding-top: 28px;
  .add-btn {
    margin: 0 63px 0 auto;
    width: 156px;
    height: 42px;
    background: #E9E9E9;
    border-radius: 22px;
    font-size: 14px;
    font-family: Montserrat-SemiBold, Montserrat;
    font-weight: 600;
    color: #000000;
    line-height: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition-duration: 0.3s;
    img {
      width: 14px;
      margin-right: 4px;
    }

    &:hover {
      transform: scale(1.05);
    }
  }

  .title {
    font-size: 14px;
    font-family: Montserrat-SemiBold, Montserrat;
    font-weight: 600;
    color: #000000;
    line-height: 16px;
    margin-top: 24px;
    margin-bottom: 15px;
  }

  .template-wrapper {
    display: flex;
    flex-wrap: wrap;
    .template {
      width: 120px;
      height: 86px;
      border-radius: 10px 10px 10px 10px;
      opacity: 1;
      border: 1px solid #E7E7E7;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      white-space: break-spaces;
      margin-right: 10px;
      margin-bottom: 10px;

      &:hover {
        border: 1px solid #000000;
      }
    }
  }

}

</style>
