<template>
  <div class="attributes">
    <div class="close-btn" @click="closeAttr"></div>
    <div v-show="mSelectMode === 'one' && textType.includes(mSelectOneType)">
      <p class="title">Text</p>
      <div class="text-set">
        <Select
          class="font-select"
          v-model="fontAttr.fontFamily"
          @on-change="changeFontFamily"
          size="small"
        >
          <Option
            v-for="item in fontFamilyList"
            :value="item"
            :key="'font-' + item"
            :style="{fontFamily: item}"
          >{{ item }}</Option
          >
        </Select>
        <input
          class="size-input"
          v-model="fontAttr.fontSize"
          @change="changeSize"
          type="number"
        />
      </div>
      <p class="title">Color</p>
      <ColorPicker
        v-model="baseAttr.fill"
        @on-change="(value) => changeCommon('fill', value)"
        alpha
        transfer
        size="small"
      />
    </div>
    <div v-show="mSelectMode === 'one' && mSelectOneType === 'image' || canvas.c.isDrawingMode">
      <p class="title">Layer</p>
      <div class="pre-show">
        <img :src="previewImage" alt="">
      </div>
      <p class="title">Remove Background</p>
      <div class="rembg-btns">
        <div class="btn qr" :class="{inactive: !isNft}" @click="quickRemove">Removal</div>
        <div class="btn eras" :class="{active: erasing}" @click="eras">Eraser</div>
      </div>
<!--      <div class="title">-->
<!--        <p class="title">Allowance</p>-->
<!--        <input class="allowance" type="number" value="0">-->
<!--      </div>-->
    </div>
    <div
      class="loading-mask"
      @click="loading = false"
      v-if="loading">
      <img src="../assets/loading.gif" alt="">
    </div>
  </div>
</template>

<script>
import select from '@/mixins/select'
import FontFaceObserver from 'fontfaceobserver'
import fontList from "@/assets/fonts/font";
import axios from 'axios'

export default {
  name: "SetAttributes",
  mixins: [select],
  data() {
    return {
      isNft: false,
      erasing: false,
      previewImage: '',
      textType: ['i-text', 'textbox', 'text'],
      fontFamilyList: fontList.map(item => item.fontFamily),
      loading: false,
      // common
      baseAttr: {
        opacity: 0,
        angle: 0,
        fill: '#fff',
        left: 0,
        top: 0,
        strokeWidth: 0,
        stroke: '#fff',
        shadow: {
          color: '#fff',
          blur: 0,
          offsetX: 0,
          offsetY: 0,
        }
      },
      // font
      fontAttr: {
        fontSize: 0,
        fontFamily: '',
        lineHeight: 0,
        charSpacing: 0,
        fontWeight: '',
        textBackgroundColor: '#fff',
        textAlign: '',
        fontStyle: '',
        underline: false,
        linethrough: false,
        overline: false
      },
    }
  },
  created() {
    this.event.on('selectOne', (e) => {
      const activeObject = this.canvas.c.getActiveObjects()[0];
      if (activeObject.type === 'image') {
        this.previewImage = activeObject.src
        this.showAttr()

        this.isNft = activeObject.isNft
      }
      if (activeObject) {
        // base
        this.baseAttr.opacity = activeObject.get('opacity') * 100
        this.baseAttr.fill = activeObject.get('fill')
        this.baseAttr.left = activeObject.get('left')
        this.baseAttr.top = activeObject.get('top')
        this.baseAttr.stroke = activeObject.get('stroke')
        this.baseAttr.strokeWidth = activeObject.get('strokeWidth')
        this.baseAttr.shadow = activeObject.get('shadow') || {}
        if (activeObject.type === 'i-text' || activeObject.type === 'text' || activeObject.type === 'textbox') {
          this.fontAttr.fontSize = activeObject.get('fontSize')
          this.fontAttr.fontFamily = activeObject.get('fontFamily')
          this.fontAttr.lineHeight = activeObject.get('lineHeight')
          this.fontAttr.textAlign = activeObject.get('textAlign')
          this.fontAttr.underline = activeObject.get('underline')
          this.fontAttr.linethrough = activeObject.get('linethrough')
          this.fontAttr.charSpacing = activeObject.get('charSpacing')
          this.fontAttr.overline = activeObject.get('overline')
          this.fontAttr.fontStyle = activeObject.get('fontStyle')
          this.fontAttr.textBackgroundColor = activeObject.get('textBackgroundColor')
          this.fontAttr.fontWeight = activeObject.get('fontWeight')
        }

      }

      this.selectObject()
    })
    this.canvas.c.on({
      'mode:erasing': () => {
        this.erasing = true;
      },
      'mode:normal': () => {
        this.erasing = false;
      },
      'object:added': this.addObject,
    })
  },
  methods: {
    selectObject() {
      if (!this.erasing) return
      const id = localStorage.getItem('activeId')
      this.canvas.c.forEachObject((o) => {
        if (o.id && o.id === id) {
          o.set('erasable', false)
        }
      })

      const activeObject = this.canvas.c.getActiveObjects()[0]
      localStorage.setItem('activeId', activeObject.id)
      activeObject.set('erasable', true);
      this.canvas.c.freeDrawingBrush = new this.fabric.EraserBrush(this.canvas.c);
      // this.canvas.c.freeDrawingBrush = new this.fabric.PencilBrush(this.canvas.c);
      this.canvas.c.freeDrawingBrush.width = 10;
      this.canvas.c.isDrawingMode = true;
      this.canvas.c.fire('mode:erasing')
    },
    cancelEras() {
      const id = localStorage.getItem('activeId')
      this.canvas.c.forEachObject((o) => {
        if (o.id && o.id === id) {
          o.set('erasable', false);
          this.canvas.c.setActiveObject(o)
          this.canvas.c.renderAll()
        }
      })

      this.canvas.c.isDrawingMode = false;
      this.canvas.c.fire('mode:normal')
    },
    // erasing end
    endEras() {
      this.canvas.c.fire('erasing:save')
      // const id = localStorage.getItem('activeId')
      // this.canvas.c.forEachObject((o) => {
      //   if (o.id && o.id === id) {
      //     const originSize = o.getOriginalSize()
      //     const scale = o.getObjectScaling()
      //     const zoom = this.canvas.zoom
      //     console.log(scale)
      //     const width = originSize.width * scale.scaleX * zoom
      //     const height = originSize.height * scale.scaleY * zoom
      //     const newImage = o.toDataURL({
      //       // top: o.top * zoom,
      //       // left: o.left * zoom,
      //       // width,
      //       // height,
      //       // enableRetinaScaling: true,
      //       // multiplier: zoom
      //     })
      //     o.src = newImage
      //     o.setSrc(newImage ,() => {
      //       this.canvas.c.requestRenderAll();
      //     })
      //
      //
      //   }
      // })
    },
    eras() {
      if (this.erasing) {
        const id = localStorage.getItem('activeId')
        this.canvas.c.isDrawingMode = false;
        this.canvas.c.fire('mode:normal')
        this.canvas.c.forEachObject((o) => {
          if (o.id && o.id === id) {
            o.set('erasable', false)
            this.canvas.c.setActiveObject(o)
            this.canvas.c.renderAll()
          }
        })
      } else {
        const activeObject = this.canvas.c.getActiveObjects()[0]
        localStorage.setItem('activeId', activeObject.id)
        console.log(activeObject.id)
        activeObject.set('erasable', true);
        this.canvas.c.freeDrawingBrush = new this.fabric.EraserBrush(this.canvas.c);
        // this.canvas.c.freeDrawingBrush = new this.fabric.PencilBrush(this.canvas.c);
        this.canvas.c.freeDrawingBrush.width = 10;
        this.canvas.c.isDrawingMode = true;
        this.canvas.c.fire('mode:erasing')
      }
    },
    async quickRemove() {
      if (!this.isNft) return;
      this.loading = true
      const activeObject = this.canvas.c.getActiveObjects()[0]
      const src = activeObject.src
      console.log(src)
      let res;

      if (src.indexOf('data:image/png;base64') === 0
        || src.indexOf('data:image/jpg;base64') === 0
        || src.indexOf('data:image/jpeg;base64') === 0
      ) {
        const file = activeObject.file
        const forms = new FormData()
        forms.append('file', file)
        const data = forms
        res = await axios.post('https://rembg.memeparty.app/', data, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          responseType: 'blob'
        })
      } else {
        res = await axios.get('https://rembg.memeparty.app/', {
          params: {
            url: activeObject.src
          },
          responseType: 'blob'
        })
      }
      const url = window.URL.createObjectURL(
        new Blob([res.data])
      );
      this.previewImage = url
      activeObject.src = url
      activeObject.setSrc(url ,() => {
        this.canvas.c.requestRenderAll();
        this.canvas.c.fire('object:modified');
        this.loading = false
      },{crossOrigin:'annonymous'})
    },
    showAttr() {
      this.$emit('open')
    },
    closeAttr() {
      this.$emit('close')
    },
    changeSize(e) {
      this.changeCommon('fontSize', this.fontAttr.fontSize)
    },
    // 通用属性改变
    changeCommon(key, value) {
      const activeObject = this.canvas.c.getActiveObjects()[0]
      // 透明度特殊转换
      if (key === 'opacity') {
        activeObject && activeObject.set(key, value / 100)
        this.canvas.c.renderAll()
        return
      }
      activeObject && activeObject.set(key, value)
      this.canvas.c.renderAll()
    },
    changeFontFamily(fontName) {
      if(!fontName) return

      // 跳过加载的属性
      const skipFonts = ['arial', 'Microsoft YaHei']
      if (skipFonts.includes(fontName)) {
        const activeObject = this.canvas.c.getActiveObjects()[0]
        activeObject && activeObject.set('fontFamily', fontName);
        this.canvas.c.renderAll()
        return
      }
      this.$Spin.show();
      // 字体加载
      var font = new FontFaceObserver(fontName);
      font.load(null, 150000).then(() => {
        const activeObject = this.canvas.c.getActiveObjects()[0]
        activeObject && activeObject.set('fontFamily', fontName);
        this.canvas.c.renderAll()
        this.$Spin.hide();
      }).catch((err) => {
        console.log(err)
        this.$Spin.hide();
      })
    },
  }
}
</script>

<style scoped lang="less">
.attributes {
  position: relative;
  padding-top: 60px;

  .close-btn {
    position: absolute;
    top: 24px;
    right: 24px;
    width: 32px;
    height: 32px;
    background-image: url("../assets/close-left.png");
    background-size: contain;
    cursor: pointer;
  }

  .title {
    font-size: 16px;
    font-family: Montserrat-SemiBold, Montserrat;
    font-weight: 600;
    color: #333333;
    line-height: 20px;
    margin-bottom: 10px;
    margin-top: 20px;
  }

  .pre-show {
    display: flex;
    img {
      margin: auto;
      height: 86px;
      border-radius: 10px;
    }
  }

  .rembg-btns {

    .btn {
      width: 252px;
      height: 50px;
      border-radius: 18px 18px 18px 18px;
      opacity: 1;
      border: 1px solid #E7E7E7;
      margin-bottom: 15px;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 14px;
      font-family: Montserrat-SemiBold, Montserrat;
      font-weight: 600;
      color: #434343;
      line-height: 50px;

      &.active, &:hover {
        border-color: #202020;
      }

      &.inactive {
        opacity: 0.5;
        &:hover {
          border-color: #E7E7E7;
        }
      }

      &:before {
        width: 16px;
        height: 16px;
        background-size: contain;
        background-position: center;
        margin-right: 4px;
        content: ' ';
      }

      &.ir:before {
        background-image: url("../assets/ir.png");
      }

      &.qr:before {
        background-image: url("../assets/qr.png");
      }

      &.eras:before {
        background-image: url("../assets/eras.png");
      }
    }
  }

  .allowance {
    width: 100px;
    height: 38px;
    background: #FAFAFA;
    text-align: center;
    font-size: 14px;
    font-family: Montserrat-Medium, Montserrat;
    font-weight: 500;
    color: #434343;
    line-height: 38px;
    border-radius: 10px 10px 10px 10px;
  }

  .text-set {
    display: flex;
  }
  .font-select {
    width: 214px;
    height: 45px;
    background: #FAFAFA;
    border-radius: 10px;
    margin-right: 12px;
    /deep/.ivu-select-selection  {
      border: none;
      height: 45px;
      background: #FAFAFA;
      span {
        line-height: 45px;
        height: 45px;
      }
    }
  }

  .size-input {
    height: 45px;
    font-size: 14px;
    font-family: Montserrat-Medium, Montserrat;
    font-weight: 500;
    color: #434343;
    line-height: 45px;
    width: 62px;
    background: #FAFAFA;
    border-radius: 10px;
    text-align: center;
    border: none;
    outline: none;
  }

  .loading-mask {
    background-color: rgba(0,0,0,0.6);
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 99999;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 100px;
      height: 100px;
    }
  }
}

</style>
