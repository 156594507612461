export default {
  inject: ['canvas', 'fabric', 'event'],
  data() {
    return {
      mSelectMode: '', // one | multiple
      mSelectOneType: '', // i-text | group ...
      mSelectId: '', // select id
      mSelectIds: [], // select ids
    }
  },
  created(){
    this.event.on('selectOne', (e) => {
      this.mSelectMode = 'one'
      this.mSelectId = e[0].id
      this.mSelectOneType = e[0].type
      this.mSelectIds = e.map(item => item.id)
    })

    this.event.on('selectMultiple', (e) => {
      this.mSelectMode = 'multiple'
      this.mSelectId = ''
      this.mSelectIds = e.map(item => item.id)
    })

    this.event.on('selectCancel', () => {
      this.mSelectId = ''
      this.mSelectIds = []
      this.mSelectMode = ''
      this.mSelectOneType = ''
    })
  },
  methods: {
    /**
     * @description:  save data
     * @param {Object} data
     */
    _mixinSelected({ event, selected }) {
      if(selected.length === 1) {
        const selectItem = selected[0]
        this.mSelectMode = 'one'
        this.mSelectOneType = selectItem.type
        this.mSelectId = [selectItem.id]
        this.mSelectActive = [selectItem]
      }else if(selected.length > 1){
        this.mSelectMode = 'multiple'
        this.mSelectActive = selected
        this.mSelectId = selected.map(item => item.id)
      }else{
        this._mixinCancel()
      }
    },
    /**
     * @description: cancel
     * @param {Object} data
     */
     _mixinCancel(data) {
      this.mSelectMode =''
      this.mSelectId= []
      this.mSelectActive =[]
      this.mSelectOneType = ''
    },
    /**
     * @description: copy to clipboard
     * @param {String} clipboardText
     */
     _mixinClipboard(clipboardText) {
      this.$copyText(clipboardText).then(() => {
        this.$Message.success('success')
      }, () => {
        this.$Message.error('fail')
      })
    },
  }
}
