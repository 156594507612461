import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store'
import axios from 'axios'
import ViewUI from 'view-design';
import locale from 'view-design/dist/locale/en-US';
import VueClipboard from 'vue-clipboard2'
import svgIcon from '@/components/svgIcon/index.js';
import 'view-design/dist/styles/iview.css';
import '@/assets/fonts/font.css';
import 'fullpage.js/dist/fullpage.min.css'
import VTooltip from "v-tooltip";

Vue.use(ViewUI, {locale});
Vue.use(VueClipboard)
Vue.use(svgIcon);
Vue.use(VTooltip)

Vue.prototype.$http = axios
Vue.config.productionTip = false;

new Vue({
  store,
  router,
  render: (h) => h(App),
}).$mount('#app');
