<template>
  <div class="scroll-wrapper">
    <div
      class="bg"
      :class="[`bg${activeIndex}`, whiteSlides.indexOf(activeIndex) > -1 ? 'line-dark' : '']">
      <div class="bg-image"></div>
      <div class="header line-border"></div>
      <div class="main line-border">
        <div class="left line-border">
          <div id="left-arrow" class="">
            <div class="arrow" :style="`transform: translateY(${arrowTransfer}px)`"></div>
            <div class="line"></div>
          </div>
        </div>
        <div class="content-wrapper"></div>
      </div>
    </div>
    <div id="fullpage">
      <div class="section section1" data-anchor="meme">
        <div class="box">
          <p class="title opacity-show delay-0-5">Energize Your NFT</p>
          <p class="title opacity-show delay-0-5">With Creativity</p>
          <p class="content first fade-in-bottom delay-0-7">Meme Party is a platform dedicated to providing the tools and resources for</p>
          <p class="content fade-in-bottom delay-1">NFT holders to express their creativity and share the art work with the world.</p>
          <div class="common-btn opacity-show delay-0-5" @click="toApp">Try Now</div>
          <div class="letters opacity-show delay-0-5">
            <img
              :style="`animation-duration: ${animationDuration[item]}s`"
              :class="[`letter${item}`, 'up-and-down']"
              v-for="item in 26" :key="item"
              :src="`/image/section1/${item - 1}.png`" alt="">
          </div>
        </div>
      </div>
      <div class="section section2" id="begin">
        <div class="box">
          <p class="title opacity-show delay-0-5">Capture The Mood</p>
          <p class="title opacity-show delay-0-5">With <span class="green">MEME</span></p>
          <p class="content first fade-in-bottom delay-0-7">Memeify it with meme party. Whether
            you're looking to create a meme to brighten the day, or raise awareness on a serious
            topic</p>
          <p class="content fade-in-bottom delay-1">The meme party has you covered. Get creative and start
            making memes today! </p>
          <div class="intro-wrapper opacity-show delay-0-5">
          <div
              v-for="(item, index) in section2cards"
              :key="index"
              :class="[`intro${index}`, introActive === index ? 'active' : '']"
              @mouseenter="changeIntroActive(index)"
              class="intro">
              <div class="intro-bg-deactive"></div>
              <div class="intro-bg"></div>
              <div class="intro-image-wrapper up-and-down duration-3">
                <div class="intro-image"></div>
              </div>
              <p class="index">{{`0${index + 1}`}}</p>
              <p class="intro-title">{{item.title}}</p>
              <div
                class="intro-content"
              >
                <p
                  v-for="(text, textIndex) in item.content"
                  :key="textIndex"
                >{{text}}</p>
              </div>
              <div class="right-arrow"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="section section3">
        <div class="box">
          <p class="title opacity-show delay-0-5">From Doge Club</p>
          <p class="title last opacity-show delay-0-5">For All NFT <span class="green">Enthusiasts</span></p>
          <p class="content fade-in-bottom delay-0-7">MEME is not only a new form of expression, but
            also a culture. Join meme party,</p>
          <p class="content fade-in-bottom delay-1">Make your nft community better connected to the
            world. </p>
          <div
            @mouseenter="pauseAnimation"
            @mouseleave="continueAnimation"
            class="nft-wrapper opacity-show delay-0-5">
            <div class="swiper-wrapper scroll">
              <img
                class="nft"
                :src="`/image/section3/1/${item}.png`"
                :class="[`nft${item}`]"
                v-for="item in 14"
                :key="item" />
              <img
                class="nft"
                :src="`/image/section3/1/${item}.png`"
                :class="[`nft${item}`]"
                v-for="item in 14"
                :key="item+14" />
            </div>
            <div class="swiper-wrapper scroll-reverse">
              <img
                class="nft"
                :src="`/image/section3/2/${item}.png`"
                :class="[`nft${item}`]"
                v-for="item in 12"
                :key="item" />
              <img
                class="nft"
                :src="`/image/section3/2/${item}.png`"
                :class="[`nft${item}`]"
                v-for="item in 12"
                :key="item+12" />
            </div>
          </div>
        </div>
      </div>
      <div class="section section4 opacity-show delay-0-5">
        <div class="box">
          <p class="title ">Explore The</p>
          <p class="title">Functions of</p>
          <p class="title"><span class="green">MEME</span></p>
          <p class="title gray">MEME</p>
          <p class="sub-title text-content fade-in-bottom delay-0-7">{{section4content[section4activeIndex].title}}</p>
          <p class="content text-content fade-in-bottom delay-1">{{section4content[section4activeIndex].content[0]}}</p>
          <p class="content text-content fade-in-bottom delay-1-3">{{section4content[section4activeIndex].content[1]}}</p>
          <div class="btns ">
            <div class="section-btn" @click="switchSection4Image(-1)"></div>
            <div class="section-btn right" @click="switchSection4Image(1)"></div>
          </div>
          <div
            class="image-wrapper"
            :class="[`image-wrapper${item}`, item === section4activeIndex + 1 ? 'active': '']"
            :style="`opacity: ${section4activeIndex === item - 1 ? 1 : 0}`"
            v-for="item in 6"
            :key="item">
            <img
              :style="`opacity: ${section4activeIndex === item - 1 ? 1 : 0}`"
              class="up-and-down image2 duration-3" :src="`/image/section4/nft${item - 1}2.png`" alt="">
            <img
              :style="`opacity: ${section4activeIndex === item - 1 ? 1 : 0}`"
              class="up-and-down image1 duration-3" :src="`/image/section4/nft${item - 1}1.png`" alt="">
          </div>
        </div>
      </div>
      <div class="section section5">
        <div class="box opacity-show delay-0-5">
          <div class="section-image">
            <img src="../assets/section5.png" class="section5-bg up-and-down duration-3" alt="">
            <img
              :style="`animation-duration: ${animationDuration[item]}s`"
              :class="[`image${item}`, 'up-and-down']"
              v-for="item in 5" :key="item"
              :src="`/image/section1/${item + 15}.png`" alt="">
          </div>
          <p class="title opacity-show delay-0-5">Express</p>
          <p class="title last opacity-show delay-0-5">Yourself</p>
          <p class="content fade-in-bottom delay-0-7">Share your creations directly in creator center.</p>
          <p class="content fade-in-bottom delay-1">Everyone can be an artist. create endless combinations of</p>
          <p class="content last fade-in-bottom delay-1-3">memes to keep your social media feed fresh and entertaining.</p>
          <div class="common-btn opacity-show delay-0-5">Coming soon</div>
        </div>
      </div>
      <div class="section section6"  data-anchor="nft">
        <div class="box opacity-show delay-0-5">
          <div class="image-wrapper">
            <img
              :style="`animation-duration: ${animationDuration[item]}s`"
              :class="[`image${item} up-and-down`]"
              v-for="item in 6" :key="item"
              :src="`/image/section6/${item - 1}.png`" alt="">
          </div>
          <p class="title white ">Mysterious</p>
          <p class="title white last">Creatures</p>
          <p class="content subtitle">Total supply: TBA</p>
          <p class="content list first fade-in-bottom delay-0-7">Limited whitelist mint: DOGC MDOG will be whitelisted</p>
          <p class="content list fade-in-bottom delay-1">Holders will receive exclusive material within MEME Party</p>
          <p class="content list fade-in-bottom delay-1-3">Activate the fund pool for building the ecosystem</p>
          <p class="content list fade-in-bottom delay-1-6">The ownership of NFT will be viewed as stock rights of the DOGC ecosystem, share the profit of creator fee, meme party and more in the future.</p>
          <div class="common-btn ">Coming Soon</div>
        </div>
      </div>
      <div class="section section7" data-anchor="roadmap">
        <div class="box">
          <div class="title-wrapper">
            <p class="title opacity-show delay-0-5">Borkmap</p>
            <p class="title sub opacity-show delay-0-5">MEME</p>
          </div>
          <div
            id="roadmap-wrapper"
            @scroll="roadmapScroll"
            class="roadmap-wrapper opacity-show delay-0-5">
            <div
              class="roadmap-inner ">
              <div class="line"></div>
              <div class="process" :style="{height: roadmapProcess + 'px'}"></div>
              <div
                class="stop-wrapper"
                v-for="(item, stopIndex) in roadmap"
                :key="item.time"
              >
                <div
                  class="card"
                  :class="{
                    plain: card.plain,
                    first: index === 0
                  }"
                  v-for="(card, index) in item.cards"
                  :key="index"
                >
                  <div class="stop-info" v-if="index === 0">
                    <div class="dot"></div>
                  </div>
                  <span
                    v-if="index === 0"
                    class="time"
                    :class="{success: stopIndex < 2}"
                  >{{item.time}}</span>
                  <img class="avatar" :src="`/image/roadmap/${stopIndex}/${index}.png`" />
                  <p class="card-title">{{card.title}}</p>
                  <p
                    v-for="(content, contentIndex) in card.content"
                    :key="contentIndex"
                    class="card-content fade-in-bottom delay-0-7">{{content}}</p>
                </div>
              </div>
            </div>
          </div>
          <div class="bottom-wrapper">
            <p class="copyright">© meme party 2022</p>
            <a
              href="https://alvarotrigo.com/fullpage"
              target="_blank"
              class="link">Made with fullPage.js</a>
          </div>
        </div>
      </div>
    </div>
    <div class="top-nav">
      <div class="logo">
        <img v-if="moving" src="../assets/logo.gif" alt="">
      </div>
      <div class="middle" :class="{dark: whiteSlides.indexOf(activeIndex) > -1}">
        <nav
          @click="moveTo('meme')"
          :class="{active: activeIndex !== 5 && activeIndex !== 6}">Meme Party</nav>
        <nav
          @click="moveTo('nft')"
          :class="{active: activeIndex === 5}"
        >NFT</nav>
        <nav
          @click="moveTo('roadmap')"
          :class="{active: activeIndex === 6}"
        >Borkmap</nav>
      </div>
      <div
        :class="{dark: whiteSlides.indexOf(activeIndex) > -1}"
        class="right">
        <div class="dc btn"><a href="https://discord.gg/cxjtYNcGQn" target="_blank"></a></div>
        <div class="tw btn"><a href="https://twitter.com/memepartyio" target="_blank"></a></div>
        <div class="connect-btn" @click="toApp">Launch APP</div>
      </div>
    </div>
    <div class="mobile-tip">
    </div>
  </div>
</template>

<script>
import anime from 'animejs'
import _ from 'lodash'
import animations from '@/utils/animation'
import fullpage from 'fullpage.js'
import roadmap from "@/utils/roadmap";
import section2cards from "@/utils/section2cards";
import section4content from '@/utils/section4content';

export default {
  name: 'IndexView',
  data() {
    return {
      activeIndex: 0,
      animationArray: [],
      fp: '',
      introActive: 0,
      section2tl: false,
      whiteSlides: [1, 2, 3],
      roadmap,
      section2cards,
      roadmapProcess: window.innerWidth > 1650 ? 380 : 318,
      moving: false,
      screenWidth: 0,
      // section4
      showNft: true,
      section4content,
      section4activeIndex: 0,
      // animation random duration
      animationDuration: []
    }
  },
  computed: {
    arrowTransfer: function() {
      const lineHeight = window.innerWidth > 1650 ? 450 : 315;
      const arrowHeight = window.innerHeight > 1650 ? 70 : 49;
      return (lineHeight - arrowHeight) / 6 * (this.activeIndex + 1)
    }
  },
  created() {
    this.screenWidth = window.innerWidth
  },
  mounted() {
    if (window.innerWidth > 1650 ) {
      document.querySelector('#roadmap-wrapper').scrollTop = 144
      console.log(document.querySelector('#roadmap-wrapper').scrollTop)
    } else {
      document.querySelector('#roadmap-wrapper').scrollTop = 80
    }
    if (document.querySelector('html').classList.value !== 'fp-enabled') {
      this.fp = new fullpage('#fullpage', {
        //options here
        licenseKey: 'KEX96-TOP9H-N39KK-681J9-DOZWO',
        scrollHorizontally: true,
        normalScrollElements: '#roadmap-wrapper',
        beforeLeave: (origin, destination, direction, trigger) => {
          this.moving = true
          setTimeout(() => {
            this.moving = false
          }, 1000)
          this.activeIndex = destination.index;
        },
        afterLoad: (origin, destination) => {
          if (destination.index === 6) {
            document.querySelector('#roadmap-wrapper').focus()
          }
        },
        afterRender: () => {
          // this.section1Animation()
        }
      })
    }

    // init section2 content text
    for (let i = 0; i < 3; i++) {
      const textWrapper = document.querySelector(`.section2 .intro${i} .intro-content`);
      textWrapper.innerHTML = textWrapper.textContent.replace(/\S/g, "<span class='letter'>$&</span>");
    }

    // calculate roadmap transfer limit
    const wrapperWidth = document.querySelector('.roadmap-wrapper').offsetWidth;
    // animation duration
    for (let i = 0; i < 27; i++) {
      this.animationDuration.push(Math.random() * 3 + 2)
    }
  },
  methods: {
    toApp() {
      this.$router.push('/app')
    },
    getDuration(index) {
      return this.animationDuration[index]
    },
    roadmapScroll(e) {
      const startLength = window.innerWidth > 1650 ? 380 : 318;
      const limit = window.innerWidth > 1650 ? 2112 : 1806;
      this.roadmapProcess = Math.min(startLength + e.target.scrollTop, limit)
    },
    switchSection4Image(type) {
      document.querySelectorAll('.section4 .fade-in-bottom').forEach(item => {
        item.setAttribute('style', 'animation-delay: unset')
        item.classList.add('fade-out')
        item.classList.remove('fade-in-bottom')
        // trigger reflow, in which way we retrigger css animation
        void item.offsetWidth;
      })
      document.querySelectorAll('.section4 .image-wrapper').forEach(item => {
        item.setAttribute('style', 'animation-delay: unset')
        item.classList.add('fade-out')
        // trigger reflow, in which way we retrigger css animation
        void item.offsetWidth;
      })
      setTimeout(() => {
        document.querySelectorAll('.section4 .text-content').forEach(item => {
          item.classList.remove('fade-out')
          item.classList.add('fade-in-bottom')
        })
        if (type + this.section4activeIndex >= this.section4content.length) {
          this.section4activeIndex = 0
        } else if (type + this.section4activeIndex < 0) {
          this.section4activeIndex = this.section4content.length -1;
        } else {
          this.section4activeIndex += type
        }
      }, 350)
    },
    pauseAnimation(e) {
      document.querySelector('.swiper-wrapper.scroll').classList.add('animation-pause')
      document.querySelector('.swiper-wrapper.scroll-reverse').classList.add('animation-pause')
    },
    continueAnimation(e) {
      document.querySelector('.swiper-wrapper').classList.remove('animation-pause')
      document.querySelector('.swiper-wrapper.scroll-reverse').classList.remove('animation-pause')
    },
    getCardWidth(stopIndex, cardIndex) {
      if (window.innerWidth < 1650) {
        return this.roadmap[stopIndex].cards[cardIndex].smallWidth
      }
      return this.roadmap[stopIndex].cards[cardIndex].width
    },
    moveTo(anchor) {
      fullpage_api.moveTo(anchor)
    },
    changeIntroActive(index) {
      if (this.introActive === index) return
      this.introActive = index
      this.section2Animation(index)
    },
    section1Animation() {
      for (let i = 1; i <= 16; i++) {
        anime({
          targets: '.section1 .letters .letter' + i,
          translateY: [-1000, 0],
          easing: 'easeOutBounce',
          duration: anime.random(500, 2000),
          delay: 1000
        });
      }
      anime({
        targets: ['.section1 .title', '.section1 .content', '.section1 .common-btn'],
        opacity: [0, 1],
        duration: 1000,
        delay: 1000
      })
    },
    section2Animation(index) {
      anime.timeline()
        .add({
          targets: `.section2 .intro${index} .intro-content .letter`,
          translateY: [100, 0],
          translateZ: 0,
          opacity: [0, 1],
          easing: "easeOutExpo",
          duration: 1400,
          delay: (el, i) => 300 + 10 * i
        })
    },
  },
}
</script>

<style scoped lang="less">
.scroll-wrapper {
  position: relative;
  width: 100%;
}

// animation
@keyframes arrow-move {
  0% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(30%);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes show {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeInBottom {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}
@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes upAndDown {
  0% {
    transform: translateY(0px);
  }
  25% {
    transform: translateY(10px);
  }
  50% {
    transform: translateY(0px);
  }
  75% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0px);
  }
}
.arrow-move {
  animation-name: arrow-move;
  animation-duration: 1s;
  animation-timing-function: ease-in-out;
}
.duration-3 {
  animation-duration: 3s;
}
.up-and-down {
  animation-name: upAndDown;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

.active {
  .fade-out {
    animation-name: fadeOut;
    animation-duration: 0.3s;
    animation-timing-function: linear;
    animation-fill-mode: forwards;
  }
  .opacity-show {
    opacity: 0;
    animation-name: show;
    animation-duration: 0.5s;
    animation-timing-function: linear;
    animation-fill-mode: forwards;
  }
  .fade-in-bottom {
    opacity: 0;
    transform: translateY(20px);
    animation-name: fadeInBottom;
    animation-duration: 0.5s;
    animation-timing-function: linear;
    animation-fill-mode: forwards;
  }
  .delay-0-5 {
    animation-delay: 0.5s;
  }
  .delay-0-7 {
    animation-delay: 0.7s;
  }
  .delay-1 {
    animation-delay: 1s;
  }
  .delay-1-3 {
    animation-delay: 1.3s;
  }
  .delay-1-6 {
    animation-delay: 1.6s;
  }
}

.bg {
  position: fixed;
  width: 100%;
  height: 100vh;
  left: 0;
  top: 0;
  padding: 20px;
  box-sizing: border-box;
  transition-duration: 0.5s;

  .bg-image {
    position: absolute;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-image: url("../assets/section4/bg.png");
    opacity: 0;
    top: 0;
    left: 0;
    transition-duration: 0.3s;
  }

  .header, .main {
    position: relative;
  }

  &.bg0 {
    background-color: #60C96F;
  }

  &.bg1 {
    background-color: #FFFFFF;
  }

  &.bg2 {
    background-color: #FFFFFF;
  }

  &.bg3 {
    background-color: #FFFFFF;
  }

  &.bg4 {
    background-color: #EE994A;
  }

  &.bg5 {
    background-color: #60C96F;
  }

  &.bg6 {
    background-color: #000000;
  }

  &.line-dark {
    .line-border {
      border-color: rgba(0, 0, 0, 0.3);
    }

    .main {
      .left {
        .arrow {
          background-image: url("../assets/arrow-b.png");
        }

        .line {
          border-left: 1px solid rgba(0, 0, 0, 0.2);
        }
      }
    }
  }

  .line-border {
    border-width: 1px;
    border-style: solid;
    box-sizing: content-box;
    border-color: rgba(255, 255, 255, .3);
  }

  .header {
    height: 70px;
    border-radius: 15px 15px 0 0;
  }

  .main {
    margin-top: -1px;
    height: calc(100% - 70px);
    border-radius: 0 0 15px 15px;
    display: flex;
    border-top: none;

    .left {
      border-left: none;
      border-top: none;
      border-bottom: none;
      width: 70px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      #left-arrow {
        width: 70px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }

      .arrow, .line {
        transition-duration: 0.3s;
      }

      .arrow {
        width: 12px;
        height: 70px;
        background-size: contain;
        background-repeat: no-repeat;
        background-image: url("../assets/arrow-w.png");
      }

      .line {
        border-left: 1px solid rgba(255, 255, 255, 0.2);
        height: 450px;
      }
    }
  }
}

#fullpage {
  position: relative;

  .section {
    position: relative;

    /deep/ .fp-overflow {
      position: relative;
      width: calc(100% - 110px);
      margin: 0 20px 0 auto;
      height: 100%;
      padding-top: 90px;
      padding-bottom: 20px;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    .box {
      width: 100%;
      height: calc(100vh - 110px);
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: relative;
    }

    .title {
      font-size: 90px;
      font-family: Chango-Regular, Chango;
      font-weight: 400;
      color: #FFFFFF;
      line-height: 93px;
      white-space: nowrap;
    }

    .content {
      white-space: nowrap;
      font-size: 18px;
      font-family: Montserrat-Medium, Montserrat;
      font-weight: 500;
      color: rgba(255, 255, 255, 0.88);
      line-height: 21px;
    }

    .common-btn {
      height: 67px;
      border-radius: 18px;
      border: 1px solid #FFFFFF;
      font-size: 20px;
      font-family: Montserrat-Bold, Montserrat;
      font-weight: bold;
      color: #FFFFFF;
      line-height: 67px;
      text-align: center;
      transition-duration: 0.3s;
      cursor: pointer;

      &:hover {
        transform: scale(1.03);
      }
    }
  }

  .section1 {
    .content.first {
      margin-top: 32px;
      white-space: break-spaces;
    }

    .common-btn {
      margin-top: 40px;
      width: 278px;

      &:hover {
        color: #60C96F;
        background-color: white;
      }
    }

    .letters {
      width: 1618px;
      height: 368px;
      overflow: visible;
      position: relative;
      @widths: 304, 231, 307, 262, 103, 120, 183, 251, 215, 283, 121, 236, 207, 76, 101, 179, 184, 132, 110, 78, 115, 146, 121, 74, 129, 144;
      @heights: 310, 197, 330, 215, 112, 73, 138, 249, 185, 265, 74, 234, 194, 68, 76, 147, 177, 132, 109, 79, 115, 141, 130, 74, 129, 144;
      @tops: 58, 170, 56, 134, 0, 211, 60, 87, 171, 43, 260, 128, 167, 68, 126, 80, 203, 74, 87, 77, 162, 258, 209, 186, 247, 80;
      @lefts: 331, 579, 141, 0, 407, 298, 525, 746, 902, 1018, 1128, 1382, 1221, 1234, 760, 77, 592, 1384, 920, 281, 472, 536, 867, 1051, 1300, -20;
      .for(@data, @i: 1) when (@i <= length(@data)) {
        @width: extract(@widths, @i);
        @height: extract(@heights, @i);
        @top: extract(@tops, @i);
        @left: extract(@lefts, @i);
        .letter@{i} {
          width: @width + 0px;
          height: @height + 0px;
          top: @top + 0px;
          left: @left + 0px;
        }
        .for(@data, (@i + 1));
      }
      .for(@tops); /* the primary call with length() function - initialization */

      img {
        position: absolute;
      }
    }
  }

  .section2 {
    .title {
      width: 1486px;
      text-align: left;
      font-size: 80px;
      line-height: 83px;
      color: #000000;

      .green {
        color: #60C96F;
      }
    }

    .content {
      width: 1486px;
      text-align: left;
      color: rgba(0, 0, 0, .65);

      &.first {
        margin-top: 30px;
      }
    }

    .intro-wrapper {
      margin-top: 34px;
      height: 571px;
      display: flex;
      align-items: flex-end;

      .intro {
        width: 488px;
        height: 341px;
        position: relative;
        cursor: pointer;
        transition-duration: 0.5s;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        padding-left: 50px;

        .intro-bg-deactive {
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          background-color: #F9F9F9;
          height: 341px;
          transition-duration: 0.5s;
        }

        .intro-bg {
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 0;
          opacity: 0;
          background-size: 488px;
          transition-duration: 0.5s;
          background-repeat: no-repeat;
        }

        .intro-image-wrapper {
          position: absolute;
          bottom: 242px;
          right: 0;
        }

        .intro-image {
          background-size: contain;
          transition-duration: 0.5s;
          background-repeat: no-repeat;
          transform-origin: bottom center;
          transform: scale(0);
        }

        .index, .intro-title, .intro-content {
          position: relative;
          transition-duration: 0.5s;
        }

        .index {
          font-size: 70px;
          font-family: Chango-Regular, Chango;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.09);
          line-height: 82px;
          margin-bottom: 11px;
          margin-top: 34px;
        }

        .intro-title {
          font-size: 24px;
          font-family: Montserrat-Bold, Montserrat;
          font-weight: bold;
          color: #333333;
          line-height: 28px;
          margin-bottom: 16px;
        }

        .intro-content {
          opacity: 0;
          width: 375px;
          height: 60px;
          font-size: 16px;
          font-family: Montserrat-Medium, Montserrat;
          font-weight: 500;
          color: rgba(255, 255, 255, 0.65);
          line-height: 19px;
          margin-bottom: 128px;
        }

        .right-arrow {
          width: 0;
          position: absolute;
          height: 15px;
          background-image: url("../assets/section2/arrow.png");
          background-size: 80px 15px;
          background-position: top left;
          opacity: 0;
          transition-delay: 0.3s;
          transition-duration: 0.3s;
          left: 50px;
          bottom: 50px;
        }

        &.active {
          height: 571px;
          background-color: #FFFFFF;

          .intro-bg-deactive {
            opacity: 0;
          }

          .intro-image {
            transform: scale(1);
          }

          .intro-bg {
            opacity: 1;
            height: 466px;
          }

          .index {
            color: #F5F4FF;
          }

          .intro-title {
            color: #FFFFFF;
          }

          .intro-content {
            opacity: 1;
          }

          .right-arrow {
            opacity: 1;
            width: 80px;
          }
        }

        &.intro0 {
          .intro-bg-deactive {
            border-top-left-radius: 30px
          }

          .intro-bg {
            background-image: url("../assets/section2/bg0.png");
          }

          .intro-image {
            width: 359px;
            height: 365px;
            background-image: url("../assets/section2/role0.png");
          }
        }

        &.intro1 {
          .intro-bg {
            background-image: url("../assets/section2/bg1.png");
          }

          .intro-image {
            position: relative;
            right: 40px;
            width: 229px;
            height: 299px;
            background-image: url("../assets/section2/role1.png");
          }
        }

        &.intro2 {
          .intro-bg-deactive {
            border-top-right-radius: 30px
          }

          .intro-bg {
            background-image: url("../assets/section2/bg2.png");
          }

          .intro-image {
            position: relative;
            right: -30px;
            width: 316px;
            height: 357px;
            background-image: url("../assets/section2/role2.png");
          }
        }
      }
    }
  }

  .section3 {
    @keyframes scroll {
      0% {
        transform: translateX(0);
      }
      100% {
        transform: translateX(-50%);
      }
    }
    @keyframes scroll-reverse {
      0% {
        transform: translateX(-50%);
      }
      100% {
        transform: translateX(0%);
      }
    }
    .animation-pause {
      animation-play-state: paused;
    }

    .scroll {
      animation-name: scroll;
      animation-duration: 30s;
      animation-timing-function: linear;
      animation-iteration-count: infinite;
    }
    .scroll-reverse {
      animation-name: scroll-reverse;
      animation-duration: 15s;
      animation-timing-function: linear;
      animation-iteration-count: infinite;
    }
    .title {
      font-size: 80px;
      line-height: 94px;
      color: #1B1B1D;
      .green {
        color: #60C96F;
      }
    }

    .title, .content {
      width: 1580px;
      text-align: left;
      padding-left: 36px;
    }
    .content {
      color: #1D1D1F;
    }

    .title.last {
      margin-bottom: 30px;
    }

    .nft-wrapper {
      margin-top: 50px;
      height: 440px;
      width: 1580px;
      overflow: hidden;
      position: relative;

      .swiper-wrapper {
        display: flex;
        position: absolute;

        &.scroll {
          top: 10px;
        }
        &.scroll-reverse {
          bottom: 10px;
        }
      }

      .nft {
        flex-shrink: 0;
        background-repeat: no-repeat;
        height: 200px;
        border-radius: 20px;
        background-size: contain;
        margin-right: 20px;
        transition-duration: 0.3s;
        &:hover{
          transform: scale(1.1);
        }
      }
    }
  }

  .section6 {
    .box {
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
    }

    .image-wrapper {
      position: absolute;
      height: 714px;
      width: 822px;
      left: 50%;
      top: 50%;
      margin-top: -366px;
      margin-left: -860px;
      img {
        position: absolute;
      }

      @widths: 413, 455, 261, 391, 460, 520;
      @heights: 410, 454, 368, 391, 460, 108;
      @tops: 108, 242, 134, 297, 274, 0;
      @lefts: 257, 379, 108, 0, 259, 114;
      .for(@data, @i: 1) when (@i <= length(@data)) {
        @width: extract(@widths, @i);
        @height: extract(@heights, @i);
        @top: extract(@tops, @i);
        @left: extract(@lefts, @i);
        .image@{i} {
          width: @width + 0px;
          height: @height + 0px;
          top: @top + 0px;
          left: @left + 0px;
        }
        .for(@data, (@i + 1));
      }
      .for(@tops); /* the primary call with length() function - initialization */

    }

    .title, .content {
      margin-left: 645px;
      text-align: left;
      width: 610px;
    }

    .subtitle {
      height: 24px;
      font-size: 20px;
      font-family: Montserrat-SemiBold, Montserrat;
      font-weight: 600;
      line-height: 23px;
      margin-bottom: 10px;
    }
    .content.list {
      margin-bottom: 5px;
      padding-left: 12px;
      white-space: break-spaces;

      &:before {
        position: absolute;
        left: 0px;
        width: 6px;
        height: 6px;
        background-color: white;
        border-radius: 3px;
        content: ' ';
        top: 7px;
      }
    }

    .common-btn {
      margin-left: 320px;
      margin-bottom: 80px;
    }

    .title {
      font-size: 90px;
      line-height: 105px;
      &.white {
        color: #FFFFFF;
      }
      &.last {
        margin-bottom: 40px;
      }
    }

    .common-btn {
      margin-top: 50px;
      width: 288px;
      height: 70px;
      background: #FFFFFF;
      border-radius: 20px;
      border: 1px solid rgba(255, 255, 255, 0.3);
      font-size: 24px;
      text-align: center;
      font-family: Montserrat-SemiBold, Montserrat;
      font-weight: 600;
      color: #333333;
      line-height: 70px;
    }
  }

  .section4 {
    .box {
      position: relative;

      .title, .content, .btns, .sub-title {
        width: 1530px;
        text-align: left;
      }

      .title {
        color: #000000;;
        font-size: 80px;

        .green {
          color: #60C96F;
        }

        &.gray {
          margin-top: 16px;
          font-size: 160px;
          font-weight: 400;
          color: rgba(193, 193, 193, 0.1);
          line-height: 166px;
        }
      }

      .btns {
        margin-top: 50px;
        display: flex;

        .section-btn {
          cursor: pointer;
          height: 50px;
          width: 50px;
          background-image: url("../assets/section4/btn.png");
          background-size: contain;
          background-repeat: no-repeat;
          margin-right: 15px;
          transition-duration: 0.3s;

          &.right {
            background-image: url("../assets/section4/btn-right.png");
          }

          &:hover {
            transform: scale(1.1);
          }
        }
      }

      .sub-title {
        margin-top: 20px;
        font-size: 26px;
        font-family: Montserrat-Bold, Montserrat;
        font-weight: bold;
        color: #000000;
        line-height: 30px;
        margin-bottom: 6px;
      }

      .content {
        color: rgba(0, 0, 0, 0.65);
      }

      .image-wrapper {
        position: absolute;
        transition-duration: 0.3s;
        width: 855px;
        height: 727px;
        left: 50%;
        margin-left: -79px;
        top: 50%;
        margin-top: -348px;
        // top 227
        // right 149
        // left 916
        // bottom 126

        @widths1: 396, 396, 346, 286, 396, 326;
        @heights1: 697, 598, 260, 374, 598, 413;
        @widths2: 562, 465, 550, 600, 528, 476;
        @heights2: 598, 612, 550, 600, 528, 682;
        @tops1: 0, 79, 222, 157, 5, 252;
        @lefts1: 92, 84, 14, 0, 60, 0;
        @tops2: 129, 40, 86, 63, 124, 15;
        @lefts2: 293, 367, 202, 117, 262, 185;
        .for(@data, @i: 1) when (@i <= length(@data)) {
          @width1: extract(@widths1, @i);
          @height1: extract(@heights1, @i);
          @top1: extract(@tops1, @i);
          @left1: extract(@lefts1, @i);
          @width2: extract(@widths2, @i);
          @height2: extract(@heights2, @i);
          @top2: extract(@tops2, @i);
          @left2: extract(@lefts2, @i);
          &.image-wrapper@{i} {
            .image1 {
              width: @width1 + 0px;
              height: @height1 + 0px;
              top: @top1 + 0px;
              left: @left1 + 0px;
            }
            .image2 {
              width: @width2 + 0px;
              height: @height2 + 0px;
              top: @top2 + 0px;
              left: @left2 + 0px;
            }
          }
          .for(@data, (@i + 1));
        }
        .for(@tops1); /* the primary call with length() function - initialization */

        img {
          position: absolute;
          left: 0;
          top: 0;
          transition-duration: 0.5s;
          &.image2 {
            transition-delay: 0.1s;
          }
          &.image1 {
            transition-delay: 0.3s;
          }
        }
      }
    }
  }

  .section5 {
    .box {
      position: relative;
      .section-image {
        width: 859px;
        height: 609px;
        position: absolute;
        left: 50%;
        margin-left: -73px;
        top: 50%;
        margin-top: -304px;

        .section5-bg {
          width: 100%;
          left: 0;
          top: 0;

        }
        img {
          position: absolute;
        }

        @widths: 184, 132, 110, 78, 115, 90, 206, 74, 64;
        @heights: 177, 132, 109, 79, 115, 52, 206, 74, 64;
        @tops:  300, 184, 0, 212, 108, 238, 312, 436, 300;
        @lefts: 122, 656, 310, 112, 455, 248, 567, 228, 610;
        .for(@data, @i: 1) when (@i <= length(@data)) {
          @width: extract(@widths, @i);
          @height: extract(@heights, @i);
          @top: extract(@tops, @i);
          @left: extract(@lefts, @i);
          .image@{i} {
            width: @width + 0px;
            height: @height + 0px;
            top: @top + 0px;
            left: @left + 0px;
          }
          .for(@data, (@i + 1));
        }
        .for(@tops); /* the primary call with length() function - initialization */

      }

      .title, .content {
        text-align: left;
        width: 1530px;
      }

      .common-btn {
        margin-left: -1252px;
        background-color: transparent;
        color: white;
        border: 1px solid rgba(255,255,255,0.3);
        &:hover {
          color: #EE994A;
          background-color: #FFFFFF;
        }
      }

      .title {
        font-size: 80px;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 94px;

        &.last {
          margin-bottom: 34px;
        }
      }

      .content {
        &.last {
          margin-bottom: 45px;
        }
      }

      .common-btn {
        height: 67px;
        width: 278px;
        font-size: 20px;
        font-family: Montserrat-Bold, Montserrat;
        font-weight: bold;
        margin-bottom: 50px;
      }
    }
  }

  .section7 {
    .box {
      flex-direction: row;
    }
    .title-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 716px;
    }

    .title {
      font-size: 80px;
      line-height: 83px;
      margin-right: 77px;
      &.sub {
        font-size: 128px;
        font-family: Chango-Regular, Chango;
        font-weight: 400;
        color: #222222;
        line-height: 133px;
        margin-bottom: 26px;
      }
    }

    .roadmap-wrapper {
      flex-shrink: 0;
      height: 736px;
      width: 880px;
      margin: 0;
      overflow: hidden;
      position: relative;
      overflow-y: scroll;
      margin-bottom: 40px;
      &::-webkit-scrollbar {
        height: 0;
        width: 0;
      }

      &:before, &:after {
        position: sticky;
        width: 100%;
        height: 52px;
        display: block;
        content: ' ';
        filter: blur(10px);
        left: 0;
        background: #000;
        z-index: 2;
      }

      &:before {
        top: -26px;
      }
      &:after {
        bottom: -26px;
      }
      .roadmap-inner {
        position: relative;
        display: flex;
        flex-direction: column;
        transition-duration: 0.3s;
        .line, .process {
          position: absolute;
          top: 152px;
        }
        .line {
          left: 47px;
          height: 2112px;
          border: 1px dashed rgba(121,121,121,0.45);
        }
        .process {
          left: 45px;
          height: 380px;
          width: 6px;
          border-radius: 3px;
          background-color: #60C96F;
          transition-duration: 0.3s;
          transition-timing-function: ease-in-out;
          transition-delay: 0.1s;
        }
      }

      .stop-wrapper {
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        flex-shrink: 0;
        .card {
          width: 641px;
          padding: 26px;
          position: relative;
          background: #151515;
          box-shadow: 0 4px 4px 0 rgba(0,0,0,0.25);
          border-radius: 27px;
          margin-top: 30px;
          margin-right: 0;
          margin-left: auto;

          .stop-info {
            position: absolute;
            display: flex;
            flex-direction: column;
            justify-content: center;
            height: 100%;
            left: -205px;
            top: 0;
            .dot {
              width: 28px;
              height: 28px;
              position: relative;
              &:before {
                position: absolute;
                width: 14px;
                height: 14px;
                background: #60C96F;
                left: 7px;
                top: 7px;
                content: ' ';
                border-radius: 7px;
              }

              &:after {
                position: absolute;
                top: 0;
                height: 0;
                content: ' ';
                width: 28px;
                height: 28px;
                background: rgba(96,201,111,0.2);
                border-radius: 14px;
              }
            }
          }
          .time {
            color: #60C96F;
            margin-bottom: 23px;
            font-size: 20px;
            line-height: 24px;

            &.success {
              background-image: url("../assets/section7/success.png");
              background-position: right;
              background-size: 24px;
              background-repeat: no-repeat;
              padding-right: 27px;
            }
          }
          .avatar {
            position: absolute;
            left: -157px;
            top: 50%;
            transform: translateY(-50%);
            object-fit: contain;
            height: 121px;
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
          }
          .card-title {
            font-size: 24px;
            font-family: Chango-Regular, Chango;
            font-weight: 400;
            color: #FFFFFF;
            line-height: 28px;
            margin-bottom: 15px;
          }
          .card-content {
            font-size: 16px;
            font-family: Montserrat-Medium, Montserrat;
            font-weight: 500;
            color: #F3F3F6;
            line-height: 19px;
            padding-left: 18px;
            margin-bottom: 10px;
            position: relative;
            &:before {
              position: absolute;
              content: ' ';
              display: block;
              width: 8px;
              height: 8px;
              border-radius: 4px;
              background: #60C96F;
              left: 0;
              top: 6px;
            }
          }
        }
      }
      .btns {
        margin-top: 40px;
        margin-left: -65px;
        left: 50%;
        position: relative;
        width: 130px;
        display: flex;
        justify-content: space-between;

        .btn {
          width: 55px;
          height: 55px;
          background-size: 55px;
          cursor: pointer;
          background-image: url("../assets/section7/btn.png");

          &:hover {
            background-image: url("../assets/section7/btnh.png");
            transform: rotate(180deg);
          }

          &.right {
            transform: rotate(180deg);
            &:hover {
              transform: rotate(0);
            }
          }
        }
      }
    }

    .bottom-wrapper {
      position: absolute;
      bottom: 0;
      display: flex;
      height: 77px;
      justify-content: space-between;
      align-items: center;
      padding-left: 120px;
      padding-right: 190px;
      width: 100%;
      p, a {
        font-size: 14px;
        font-family: Montserrat-Medium, Montserrat;
        font-weight: 500;
        color: #A9A9A9;
        line-height: 16px;
      }
    }
  }
}

.top-nav {
  position: fixed;
  top: 20px;
  height: 70px;
  display: flex;
  width: 100%;
  padding: 0 28px 0 33px;
  justify-content: space-between;
  align-items: center;

  .logo {
    width: 100px;
    height: 100px;
    background-image: url("../assets/logo.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .middle {
    margin-left: 60px;
    display: flex;
    color: white;

    &.dark {
      color: black;
    }

    nav {
      margin: 0 25px;
      font-size: 16px;
      font-family: Montserrat-Medium, Montserrat;
      font-weight: 500;
      line-height: 19px;
      cursor: pointer;
      opacity: 0.4;
      transition-duration: 0.3s;

      &.active {
        opacity: 1;
      }
    }
  }

  .right {
    display: flex;
    align-items: center;
    .btn {
      width: 50px;
      height: 50px;
      border-radius: 25px;
      border: 1px solid rgba(255, 255, 255, 0.3);
      background-size: 57%;
      background-position: center;
      background-repeat: no-repeat;
      margin-right: 16px;
      transition-duration: 0.3s;
      cursor: pointer;
      position: relative;

      a {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }

      &.os {
        background-image: url("../assets/os-w.png");
      }

      &.tw {
        background-image: url("../assets/tw-w.png");
      }

      &.dc {
        background-image: url("../assets/dc-w.png");
      }
    }
    .connect-btn {
      cursor: pointer;
      width: 185px;
      height: 54px;
      border-radius: 10px;
      opacity: 1;
      border: 1px solid rgba(255, 255, 255, 0.3);
      text-align: center;
      font-size: 16px;
      font-family: Montserrat-Bold, Montserrat;
      font-weight: bold;
      color: #FFFFFF;
      line-height: 54px;
      transition-duration: 0.3s;

    }
    &.dark {
      .btn {
        border-color: rgba(0, 0, 0, 0.3);

        &.os {
          background-image: url("../assets/os-b.png");
        }

        &.tw {
          background-image: url("../assets/tw-b.png");
        }
        &.dc {
          background-image: url("../assets/dc-b.png");
        }
      }
      .connect-btn {
        border: 1px solid rgba(0, 0, 0, 0.3);
        color: #000000;
      }
    }


  }

  .left-btns {
    position: fixed;
    bottom: 20px;
    left: 20px;
    width: 70px;
    display: flex;
    flex-direction: column;
    align-items: center;

  }

  &.dark {
  }
}

/deep/ .fp-watermark {
  display: none;
}

@media only screen and (max-width: 1650px) {
  .bg {
    padding: 20px;

    .main {
      .left {
        width: 52px;

        .arrow {
          width: 8px;
          height: 49px;
        }

        .line {
          height: 315px;
        }
      }
    }
  }

  #fullpage {
    .section {
      /deep/ .fp-overflow {
        width: calc(100% - 92px);
      }

      .box {
        height: calc(100vh - 92px);
      }

      .title {
        font-size: 64px;
        line-height: 66px;
      }

      .content {
        font-size: 16px;
        line-height: 19px;
      }

      .common-btn {
        height: 54px;
        border-radius: 10px;
        font-size: 16px;
        line-height: 54px;
      }
    }

    .section1 {
      .content.first {
        margin-top: 28px;
      }

      .common-btn {
        margin-top: 20px;
        width: 232px;
      }

      .letters {
        width: 1040px;
        height: 250px;

        @widths: 195, 148, 197, 168, 66, 77, 117, 161, 138, 182, 78, 151, 133, 49, 65, 115, 118, 85, 71, 50, 74, 94, 78, 47, 83, 92;
        @heights: 199, 126, 212, 138, 72, 47, 89, 160, 119, 170, 47, 150, 124, 44, 49, 94, 114, 85, 70, 51, 74, 90, 83, 47, 83, 92;
        @tops: 37, 109, 36, 86, 0, 135, 38, 56, 110, 28, 167, 82, 107, 44, 81, 51, 130, 47, 56, 49, 104, 165, 134, 119, 158, 51;
        @lefts: 212, 371, 90, 0, 261, 191, 337, 479, 579, 653, 724, 886, 783, 792, 488, 49, 380, 888, 590, 180, 303, 344, 556, 674, 834, -13;
        .for(@data, @i: 1) when (@i <= length(@data)) {
          @width: extract(@widths, @i);
          @height: extract(@heights, @i);
          @top: extract(@tops, @i);
          @left: extract(@lefts, @i);
          .letter@{i} {
            width: @width + 0px;
            height: @height + 0px;
            top: @top + 0px;
            left: @left + 0px;
          }
          .for(@data, (@i + 1));
        }
        .for(@tops); /* the primary call with length() function - initialization */
      }
    }

    .section2 {
      .title {
        width: 1008px;
        font-size: 64px;
        line-height: 66px;
      }

      .content {
        width: 1008px;
      }

      .intro-wrapper {
        margin-top: 17px;
        height: 345px;

        .intro {
          width: 336px;
          height: 227px;
          padding-left: 20px;

          .intro-bg-deactive {
            height: 227px;
          }

          .intro-bg {
            background-size: 336px;
          }

          .intro-image-wrapper {
            bottom: 179px;
          }

          .index {
            font-size: 40px;
            line-height: 47px;
            margin-bottom: 9px;
            margin-top: 20px;
          }

          .intro-title {
            font-size: 20px;
            line-height: 24px;
            margin-bottom: 10px;
          }

          .intro-content {
            width: 310px;
            height: 51px;
            font-size: 14px;
            line-height: 17px;
            margin-bottom: 83px;
          }

          .right-arrow {
            height: 15px;
            background-size: 80px 15px;
            left: 20px;
            bottom: 26px;
          }

          &.active {
            height: 345px;

            .intro-bg {
              height: 311px;
            }

            .right-arrow {
              width: 80px;
            }
          }

          &.intro0 {
            .intro-bg-deactive {
              border-top-left-radius: 20px
            }

            .intro-image {
              width: 179px;
              height: 182px;
            }
          }

          &.intro1 {
            .intro-image {
              right: 20px;
              width: 114px;
              height: 149px;
            }
          }

          &.intro2 {
            .intro-bg-deactive {
              border-top-right-radius: 20px
            }

            .intro-image {
              right: -15px;
              width: 158px;
              height: 178px;
            }
          }
        }
      }
    }

    .section3 {
      .title {
        font-size: 64px;
        line-height: 75px;
      }

      .title, .content {
        width: 1001px;
        padding: 0;
      }

      .title.last {
        margin-bottom: 20px;
      }

      .nft-wrapper {
        margin-top: 33px;
        height: 286px;
        width: 1005px;

        &.scroll {
          top: 5px;
        }

        &.scroll-reverse {
          bottom: 5px;
        }

        .nft {
          height: 130px;
          border-radius: 20px;
          margin-right: 20px;
        }

      }
    }

    .section6 {
      .image-wrapper {
        height: 458px;
        width: 527px;
        margin-top: -230px;
        margin-left: -625px;

        @widths: 265, 292, 167, 251, 295, 333;
        @heights: 263, 291, 236, 251, 295, 69;
        @tops: 69, 155, 86, 191, 176, 0;
        @lefts: 165, 243, 69, 0, 166, 73;
        .for(@data, @i: 1) when (@i <= length(@data)) {
          @width: extract(@widths, @i);
          @height: extract(@heights, @i);
          @top: extract(@tops, @i);
          @left: extract(@lefts, @i);
          .image@{i} {
            width: @width + 0px;
            height: @height + 0px;
            top: @top + 0px;
            left: @left + 0px;
          }
          .for(@data, (@i + 1));
        }
        .for(@tops); /* the primary call with length() function - initialization */

      }
      .title, .content {
        margin-left: 380px;
        width: 510px;
      }

      .common-btn {
        margin-left: 96px;
        margin-bottom: 64px;

        &:hover {
          color: #60C96F;
          background-color: white;
        }
      }

      .title {
        &.white {
          font-size: 74px;
          line-height: 87px;
        }

        &.green {
          font-size: 74px;
          line-height: 87px;
          margin-top: 0;
        }

        &.orange {
          font-size: 74px;
          line-height: 87px;
          margin-bottom: 17px;
          margin-top: 0;
        }
      }

      .common-btn {
        margin-top: 35px;
        width: 222px;
        height: 56px;
        border-radius: 18px;
        border: 1px solid rgba(255, 255, 255);
        font-size: 20px;
        color: white;
        background-color: transparent;
        line-height: 56px;
      }
    }

    .section4 {
      .box {

        .title, .content, .btns, .sub-title {
          width: 997px;
        }

        .title {
          font-size: 64px;

          &.gray {
            margin-top: 11px;
            font-size: 100px;
            line-height: 104px;
          }
        }

        .btns {
          margin-top: 31px;

          .section-btn {
            height: 50px;
            padding: 0 15px;
            border-radius: 13px;
            font-size: 18px;
            line-height: 50px;
            margin-right: 15px;
          }
        }

        .sub-title {
          margin-top: 34px;
          font-size: 26px;
          margin-bottom: 6px;
        }

        .image-wrapper {
          width: 548px;
          height: 466px;
          margin-left: 10px;
          margin-top: -223px;

          @widths1: 254, 254, 222, 183, 254, 209;
          @heights1: 447, 384, 167, 240, 384, 265;
          @widths2: 359, 298, 353, 385, 339, 305;
          @heights2: 384, 393, 353, 385, 339, 437;
          @tops1: 0, 51, 142, 100, 3, 162;
          @lefts1: 59, 54, 8, 0, 38, 0;
          @tops2: 83, 26, 55, 40, 80, 10;
          @lefts2: 188, 235, 130, 75, 168, 119;
          .for(@data, @i: 1) when (@i <= length(@data)) {
            @width1: extract(@widths1, @i);
            @height1: extract(@heights1, @i);
            @top1: extract(@tops1, @i);
            @left1: extract(@lefts1, @i);
            @width2: extract(@widths2, @i);
            @height2: extract(@heights2, @i);
            @top2: extract(@tops2, @i);
            @left2: extract(@lefts2, @i);
            &.image-wrapper@{i} {
              .image1 {
                width: @width1 + 0px;
                height: @height1 + 0px;
                top: @top1 + 0px;
                left: @left1 + 0px;
              }
              .image2 {
                width: @width2 + 0px;
                height: @height2 + 0px;
                top: @top2 + 0px;
                left: @left2 + 0px;
              }
            }
            .for(@data, (@i + 1));
          }
          .for(@tops1); /* the primary call with length() function - initialization */
        }
      }
    }

    .section5 {
      .box {
        .section-image {
          width: 558px;
          height: 396px;
          margin-left: -47px;
          margin-top: -198px;

          @widths: 118, 85, 71, 50, 74, 58, 132, 47, 41;
          @heights: 114, 85, 70, 51, 74, 33, 132, 47, 41;
          @tops:  192, 118, 0, 136, 69, 153, 200, 280, 192;
          @lefts: 78, 421, 199, 72, 292, 159, 364, 146, 391;
          .for(@data, @i: 1) when (@i <= length(@data)) {
            @width: extract(@widths, @i);
            @height: extract(@heights, @i);
            @top: extract(@tops, @i);
            @left: extract(@lefts, @i);
            .image@{i} {
              width: @width + 0px;
              height: @height + 0px;
              top: @top + 0px;
              left: @left + 0px;
            }
            .for(@data, (@i + 1));
          }
          .for(@tops); /* the primary call with length() function - initialization */

        }

        .title, .content {
          width: 995px;
        }

        .common-btn {
          margin-left: -763px;
        }

        .title {
          font-size: 52px;
          line-height: 61px;

          &.last {
            margin-bottom: 22px;
          }
        }

        .content {
          &.last {
            margin-bottom: 45px;
          }
        }

        .common-btn {
          height: 56px;
          width: 240px;
          line-height: 56px;
          margin-bottom: 34px;
        }
      }
    }
    .section7 {
      .title-wrapper {
        height: 559px;
      }

      .title {
        font-size: 52px;
        line-height: 56px;
        margin-right: 50px;
        &.sub {
          font-size: 83px;
          line-height: 86px;
          margin-bottom: 17px;
        }
      }

      .roadmap-wrapper {
        height: 573px;
        width: 572px;
        margin-bottom: 24px;
        &:before, &:after {
          height: 34px;
        }

        &:before {
          top: -17px;
        }
        &:after {
          bottom: -17px;
        }
        .roadmap-inner {
          .line, .process {
            top: 118px;
          }
          .line {
            left: 31px;
            height: 1806px;
          }
          .process {
            left: 30px;
            height: 247px;
            width: 4px;
            border-radius: 2px;
          }
        }

        .stop-wrapper {
          margin-top: 13px;
          .card {
            width: 417px;
            padding: 17px;
            border-radius: 17px;
            margin-top: 20px;

            .stop-info {
              left: -133px;
              .dot {
                width: 20px;
                height: 20px;
                &:before {
                  width: 10px;
                  height: 10px;
                  left: 5px;
                  top: 5px;
                  border-radius: 5px;
                }

                &:after {
                  width: 20px;
                  height: 20px;
                  border-radius: 10px;
                }
              }
            }
            .time {
              margin-bottom: 14px;
              font-size: 14px;
              line-height: 16px;

              &.success {
                background-size: 14px;
                padding-right: 15px;
              }
            }
            .avatar {
              left: -102px;
              height: 79px;
            }
            .card-title {
              font-size: 18px;
              line-height: 20px;
              margin-bottom: 10px;
            }
            .card-content {
              font-size: 14px;
              line-height: 17px;
              padding-left: 16px;
              margin-bottom: 8px;
              &:before {
                width: 7px;
                height: 7px;
                border-radius: 3px;
                top: 5px;
              }
            }
          }
        }
      }

      .bottom-wrapper {
        height: 64px;
        padding-left: 100px;
        padding-right: 159px;
        p, a {
          font-size: 12px;
          line-height: 14px;
        }
      }
    }

  }

  .left-btns {
    bottom: 20px;
    left: 20px;
    width: 51px;

    .btn {
      width: 36px;
      height: 36px;
      border-radius: 18px;
      background-size: 57%;
      margin-bottom: 14px;
    }
  }
}
@media only screen and  (max-width: 700px) {
  .mobile-tip {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 999999;
    background-image: url("../assets/mobile.png");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }

}

</style>
