import axios, {AxiosRequestConfig} from 'axios'

// 创建 axios 实例
const request = axios.create({
  // API 请求的默认前缀
  baseURL: 'https://api.memeparty.app/api/',
  timeout: 30000, // 请求超时时间
})

// request interceptor
request.interceptors.request.use(
  (config) => {

    const token = localStorage.getItem('token') || ''
    // 如果 address 存在, 每个请求带上 address
    if (token && config.method === 'post') {
      config.headers['Authorization'] = `Bearer ${token}`
    }

    return config
  },
  (error) => {
    // do something with request error
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

export default {
  /**
   *
   * @param {String} url
   * @param {Object} data
   * @param {String} isForm
   * @param {Object} config
   *
   * 这里是通用请求
   * 如果需要特殊的配置在config中配置
   *
   */
  post(url, data, isForm, config) {
    if (isForm) {
      const formHeaders = {
        // 'X-Requested-With': 'XMLHttpRequest',
        'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
      }
      const transformRequest = (data) =>
        qs.stringify(data, {
          encode: false,
          allowDots: true,
          arrayFormat: 'indices',
        })
      config.transformRequest = [transformRequest]

      config = {headers: formHeaders, ...config}
    }
    // 文件上传
    if (isForm === 'FILE') {
      const formHeaders = {
        'Content-Type': 'multipart/form-data',
      }
      config = {headers: formHeaders, ...config}
      const forms = new FormData()
      forms.append('file', data)
      data = forms
    }
    return request.post(url, data, config)
  },
  /**
   *
   * @param {String} url
   * @param {Object} params
   * @param config
   */
  get(url, params, config) {
    return request.get(url, {params, ...config})
  },
}
