<template>
  <div class="layer-wrapper">
    <div
      class="expanded-wrapper"
      @click="foldedClick"
      :class="[folded ? 'folded' : '']"
    >
      <div class="amount" v-if="list.length > 3">
        {{list.length}}
      </div>
      <draggable
        id="drag-wrapper"
        class="drag-wrapper"
        v-model="list"
        @start="startDrag"
        @end="endDrag">
        <div
          v-for="(item, index) in list"
          :key="item.id"
          class="layer"
          @click="select(item.id)"
          :class="isSelect(item) && 'active'"
          :style="`z-index: ${list.length - index}`"
        >
          <img class="content" v-if="item.type === 'image'" :src="item.src">
          <div class="content text" v-else>{{item.text}}</div>
        </div>
      </draggable>
      <div class="btns">
        <div
          @click="scrollTo('up')"
          :class="{inactive: toEnd === 'top'}"
          class="up btn"></div>
        <div
          @click="scrollTo('down')"
          :class="{inactive: toEnd === 'bottom'}"
          class="down btn"></div>
        <div class="close btn" @click.stop="switchClose"></div>
      </div>
    </div>
  </div>
</template>

<script>
import select from "@/mixins/select";
import draggable from 'vuedraggable';
import _ from 'lodash'

export default {
  name: "SetLayer",
  mixins: [select],
  components: {
    draggable,
  },
  data() {
    return {
      list: [],
      drag: false,
      folded: false,
      toEnd: 'top'
    };
  },
  created() {
    //当选择画布中的对象时，该对象不出现在顶层。
    this.canvas.c.preserveObjectStacking = false;
    this.canvas.c.on("after:render", this.getList);
  },
  mounted() {
    document.querySelector('#drag-wrapper').onscroll = this.scrollHandle
  },
  computed: {
    foldedLength() {
      return Math.min(3, this.list.length)
    }
  },
  methods: {
    scrollTo(direction) {
      const dom = document.querySelector('#drag-wrapper')
      const step = 75
      if (direction === 'up') {
        if  (this.toEnd === 'top') return
        dom.scrollTop = Math.max(0, dom.scrollTop - step)
      }
      if (direction === 'down') {
        const maxScroll = dom.scrollHeight - dom.clientHeight
        if (this.toEnd === 'bottom') return
        dom.scrollTop = Math.max(maxScroll, dom.scrollTop + step)
      }
    },
    scrollHandle: _.throttle(function() {
      const dom = document.querySelector('#drag-wrapper')
      if (dom.scrollTop <= 0) {
        this.toEnd = 'top'
      } else if (dom.scrollHeight <= dom.scrollTop + dom.clientHeight) {
        this.toEnd = 'bottom'
      } else {
        this.toEnd = 'between'
      }
    }, 50),
    foldedClick() {
      if (this.folded) {
        this.folded = false
      }
    },
    switchClose() {
      if (!this.folded) {
        this.folded = true
        document.querySelector('#drag-wrapper').scrollTop = 0
      }
    },
    getList(e) {
      this.list = [...this.canvas.c.getObjects()].reverse().map(item => {
        const {src, type, id, name, text} = item
        return {type, id, name, text, src}
      })
    },
    // 是否选中元素
    isSelect(item) {
      return (item.id === this.mSelectId || this.mSelectIds.includes(item.id))
    },
    // 选中元素
    select(id) {
      const info = this.canvas.c.getObjects().find(item => item.id === id)
      this.canvas.c.discardActiveObject();
      this.canvas.c.setActiveObject(info);
      this.canvas.c.requestRenderAll();
    },
    startDrag(e) {
      this.drag = true;
    },
    deleteLayer(id) {
      const info = this.canvas.c.getObjects().find(item => item.id === id)
      this.canvas.c.remove(info)
      this.canvas.c.requestRenderAll()
      this.canvas.c.discardActiveObject()
    },
    endDrag(e) {
      if (e.newIndex === e.oldIndex) return;
      // select active layer
      const id = this.list[e.newIndex].id
      const info = this.canvas.c.getObjects().find(item => item.id === id)
      this.canvas.c.discardActiveObject();
      this.canvas.c.setActiveObject(info);
      if (e.newIndex === 0) {
        // to the top
        const activeObject = this.canvas.c.getActiveObjects()[0]
        activeObject && activeObject.bringToFront()
      } else if (e.newIndex === this.list.length - 1) {
        // to the end
        const activeObject = this.canvas.c.getActiveObjects()[0]
        activeObject && activeObject.sendToBack()
      } else {
        // change order
        const times = Math.abs(e.newIndex - e.oldIndex)
        const activeObject = this.canvas.c.getActiveObjects()[0]
        if (e.newIndex > e.oldIndex) {
          for (let i = 0; i < times; i++) {
            activeObject && activeObject.sendBackwards()
          }
        } else {
          for (let i = 0; i < times; i++) {
            activeObject && activeObject.bringForward()
          }
        }
      }
      this.canvas.c.renderAll()
      this.drag = false;
    }
  }
}
</script>

<style scoped lang="less">
.layer-wrapper {
  width: 150px;
  overflow: hidden;
}

.title {
  font-size: 1.6rem;
  font-family: Montserrat-SemiBold, Montserrat;
  font-weight: 600;
  color: #333333;
  line-height: 2rem;
  margin-bottom: 1rem;
}

.drag-wrapper {
  overflow: auto;
  max-height: 410px;
  transition-duration: 0.3s;
  width: 100%;
  margin-bottom: 15px;
  &::-webkit-scrollbar {
    height: 0;
    width: 0;
  }
}

.amount {
  width: 26px;
  height: 26px;
  background-color: black;
  border-radius: 13px;
  font-size: 12px;
  text-align: center;
  font-family: Montserrat-SemiBold, Montserrat;
  font-weight: 600;
  color: #FFFFFF;
  line-height: 25px;
  margin: 0 6px -23px auto;
  z-index: 999;
  display: none;
}

.layer {
  width: 120px;
  height: 59px;
  margin: 0 auto;
  transform-style: preserve-3d;
  perspective: 500px;
  margin-bottom: -20px;
  position: relative;
  transition-duration: 0.3s;

  &:last-child {
    margin-bottom: 6px;
  }

  &.active {
    .content {
      border: 2px solid #000000;
    }
  }
  img {
    object-fit: cover;
    object-position: center;
  }
  .content {
    width: 100%;
    height: 100%;
    border-radius: 10px;
    background: #FFFFFF;
    box-shadow: 0 4px 10px 0 rgba(209, 209, 209, 0.25);
    transform: rotateX(25deg);
    transform-origin: bottom center;
    text-overflow: ellipsis;
    overflow: hidden;

    &.text {
      padding: 5px;
    }
  }
}

.expanded-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  transition-duration: 0.3s;

  &.folded {
    cursor: pointer;
    .btns {
      margin-bottom: -60px;
    }
    .drag-wrapper {
      max-height: 99px;
      overflow: hidden;
      padding-bottom: 40px;
    }
    .amount {
      display: block;
    }
    .layer {
      margin-bottom: -40px;
      &.active {
        .content {
          border: none;
        }
      }
    }
  }

  .btns {
    transition-duration: 0.3s;
    width: 130px;
    height: 45px;
    background: #FFFFFF;
    box-shadow: 0 4px 15px 0 rgba(209, 209, 209, 0.25);
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px;
    margin-bottom: 0;

    .btn {
      width: 30px;
      height: 30px;
      cursor: pointer;
      background-repeat: no-repeat;
      background-position: center;
      background-size: 11px;
    }

    .up, .down {
      background-image: url("../assets/layer/arrowh.png");

      &.inactive {
        background-image: url("../assets/layer/arrow.png");
      }
    }

    .down {
      transform: rotate(180deg);
    }

    .close {
      background-image: url("../assets/layer/close.png");
    }
  }
}
</style>
